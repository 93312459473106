import { Grid, Stack, Typography } from '@mui/material';
import { APP_IMG } from 'assets';
import SitterItem from 'components/common/SitterItem';
import { REQUEST_STATUS } from 'features/overview/constant';
import { StyledSittersOverview } from 'features/overview/styled';
import { AC_BASE_REQUEST_SITTER } from 'models/overview';

export const SittersOverView = ({
  sitterOverviewData,
  statusRequest,
}: {
  sitterOverviewData: AC_BASE_REQUEST_SITTER[];
  statusRequest: string;
}) => {
  return (
    <StyledSittersOverview spacing={2}>
      <Typography variant="Web_Label_18" sx={{ opacity: '50%' }}>
        {statusRequest === REQUEST_STATUS.accepted
          ? 'Sitter Accepted'
          : 'Sitter Matched'}
      </Typography>
      <Stack spacing={1.5}>
        {sitterOverviewData.length > 0 ? (
          sitterOverviewData.map((sitter: AC_BASE_REQUEST_SITTER) => (
            <SitterItem key={sitter.id} sitter={sitter} />
          ))
        ) : (
          <Grid item xs={12} pb={5}>
            <Stack
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <img src={APP_IMG.backgroundEmpty} alt="bg_empty" />
              <Typography
                variant="Web_Label_14"
                position="absolute"
                bottom={10}
                textAlign="center"
                sx={{ width: 326, opacity: 0.5 }}
              >
                We will <b>pair you with compatible Sitter</b>, check back again
                to view the sitters.
              </Typography>
            </Stack>
          </Grid>
        )}
      </Stack>
    </StyledSittersOverview>
  );
};
