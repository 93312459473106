import { Button, Stack, Typography } from '@mui/material';
import {
  AVAILABLE_PACKAGE_ITEM_TYPE,
  PURCHASED_PACKAGE_FROM,
} from 'models/credits';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import { updateSelectedPackageId } from 'store/reducers/credits';
import { setOpenBuyCredits } from 'store/reducers/payments';
import {
  StyledBoxPopular,
  StyledBuyButtonWrapper,
  StyledPackNameWrapper,
  StyledPackWrapper,
  StyledPriceWrapper,
} from './styles';

interface PackageCreditItemProps {
  data?: AVAILABLE_PACKAGE_ITEM_TYPE;
  isPayWithCredit?: boolean;
}

const PackageCreditItem = ({
  data,
  isPayWithCredit = false,
}: PackageCreditItemProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const type = isPayWithCredit
    ? PURCHASED_PACKAGE_FROM.JOB
    : PURCHASED_PACKAGE_FROM.CREDIT;

  const onClickBuy = () => {
    dispatch(updateSelectedPackageId(data.id));
    dispatch(setOpenBuyCredits(false));
    navigate({
      pathname: APP_ROUTES.CREDITS.PAYMENT.to,
      search: `?type=${type}`,
    });
  };

  return (
    <Stack position="relative" flexWrap="wrap">
      {data.tag && <StyledBoxPopular>{data.tag}</StyledBoxPopular>}
      <StyledPackWrapper $isHasTag={!!data.tag}>
        <StyledPackNameWrapper>
          <Typography className="package-name">{data.name}</Typography>
          <Typography className="package-description">
            {data.description}
          </Typography>
        </StyledPackNameWrapper>
        <StyledPriceWrapper>
          <Typography className="total-hours">${data.price}</Typography>
          <Typography className="per-hour">{`~$${data.parent_rate}/hour`}</Typography>
        </StyledPriceWrapper>
        <StyledBuyButtonWrapper>
          {/* <Typography sx={{ opacity: !data.discount_percent ? 0 : 1 }}>
            {`Save up to ${data.discount_percent}%`}
          </Typography> */}
          <Button
            variant={data.tag ? 'contained' : 'outlined'}
            fullWidth
            onClick={onClickBuy}
          >
            Buy
          </Button>
        </StyledBuyButtonWrapper>
      </StyledPackWrapper>
    </Stack>
  );
};

export default PackageCreditItem;
