import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';
import { FC, Fragment, useCallback, useState } from 'react';
import { useAppDispatch } from 'store/hook';
import { updateStepTrial } from 'store/reducers/trial-request';
import { theme } from 'theme';
import {
  TERMS_CONTENT,
  TermsSection,
  TermsSubSection,
} from '../../../../helpers';
import UserSign from '../UserSign';
import {
  DialogSubTitleText,
  DialogTitleText,
  StyledDialogContent,
  StyledDialogTitle,
} from './styles';

interface TermsDialogProps {
  open: boolean;
  onClose: () => void;
}

const TermsDialog: FC<TermsDialogProps> = ({ onClose, ...rest }) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isScrolledToBottom, setIsBottom] = useState<boolean>(false);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const element = event.currentTarget;
      const hasScrolledToEnd =
        Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight,
        ) <= 1;

      if (hasScrolledToEnd && !isScrolledToBottom) {
        setIsBottom(true);
      }
    },
    [isScrolledToBottom],
  );

  const renderSubSection = (subSection: TermsSubSection, index: number) => (
    <Fragment key={index}>
      <Typography>{subSection.content}</Typography>
    </Fragment>
  );

  const renderSection = (section: TermsSection, index: number) => (
    <Fragment key={index}>
      <Typography>{section.mainTitle}</Typography>
      <Typography>{section.mainContent}</Typography>
      {section.subSections?.map((subItem, subIndex) =>
        renderSubSection(subItem, subIndex),
      )}
    </Fragment>
  );

  const onSubmitCallback = () => {
    dispatch(updateStepTrial(3));
    onClose();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      PaperProps={{
        sx: { maxWidth: '100%', m: 5, p: 5, gap: 2.5 },
      }}
      {...rest}
      onClose={onClose}
    >
      <StyledDialogTitle>
        <DialogTitleText>{TERMS_CONTENT.TITLE}</DialogTitleText>
        <DialogSubTitleText>{TERMS_CONTENT.SUBTITLE}</DialogSubTitleText>
      </StyledDialogTitle>
      <StyledDialogContent onScroll={handleScroll}>
        <Typography mb={2.5}>{TERMS_CONTENT.LAST_REVISED}</Typography>
        <Typography>{TERMS_CONTENT.WELCOME_TEXT}</Typography>
        {TERMS_CONTENT.SECTIONS.map((item, index) =>
          renderSection(item as any, index),
        )}
      </StyledDialogContent>
      <Stack spacing={1}>
        <Typography variant="Small_Body_16">
          Your Signature
          <span style={{ color: theme.palette.primary.main }}>*</span>
        </Typography>
        <UserSign
          onSubmitCallback={onSubmitCallback}
          isBottom={isScrolledToBottom}
        />
      </Stack>
    </Dialog>
  );
};

export default TermsDialog;
