import { useAuth } from 'contexts/auth';
import { Navigate, Outlet } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';

interface Props {
  children: any;
}

export const PublicRoute = ({ children }: Props) => {
  const { isLoggedIn, authRedirect, alreadyCreated } = useAuth();

  if (authRedirect.current) {
    if (isLoggedIn) {
      return (
        <Navigate
          replace
          to={
            alreadyCreated ? APP_ROUTES.OVERVIEW.to : APP_ROUTES.HOW_IT_WORKS.to
          }
        />
      );
    }
  }

  return <>{children ? children : <Outlet />}</>;
};

export default PublicRoute;
