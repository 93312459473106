import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { STORAGE_KEY } from 'constants/global';
import { ACTION_TYPE, RESPONSE_TYPE } from 'models';
import { OVERVIEW_RESPONSE } from 'models/overview';
import { getLocalStorage } from 'utils';

export const overviewApi = {
  getDataOverview: (accessToken: string) => {
    return APIInstance.get<RESPONSE_TYPE<OVERVIEW_RESPONSE>>(
      API.OVERVIEW.GET_OVERVIEW,
      {
        headers: {
          Authorization:
            'Bearer ' +
            (getLocalStorage(STORAGE_KEY.ACCESS_TOKEN) ?? accessToken),
        },
      },
    );
  },
  updateStatusSitter: (id: string, status: ACTION_TYPE) => {
    return APIInstance.patch<RESPONSE_TYPE>(
      API.OVERVIEW.UPDATE_STATUS_SITTER(id),
      {
        status,
      },
    );
  },
};
