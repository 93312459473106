import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CloseCircle } from 'assets';
import PaymentPayNow from 'components/common/Payments/components/PaymentPayNow';
import PaymentStripe from 'components/common/Payments/components/PaymentStripe';
import { STORAGE_KEY } from 'constants/global';
import { PAYMENT_MODAL_TYPE } from 'models/payments';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppSelector } from 'store/hook';
import { theme } from 'theme';
import { getLocalStorage } from 'utils';

const PaymentModalWrapper = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title-modal': {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '36px',
      paddingTop: 1,
    },
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px !important',
    '& .countdown': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'center',
    },
  },
}));

interface PaymentModalProps {
  onClose: () => void;
  methodType: PAYMENT_MODAL_TYPE;
  valueQrCode: string;
}

export const PaymentModal = ({
  methodType,
  onClose,
  valueQrCode,
  ...rest
}: PaymentModalProps) => {
  const pathname = useLocation().pathname;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMethodPayNow = methodType === PAYMENT_MODAL_TYPE.PAY_NOW;
  const { dataPaymentJob } = useAppSelector((state) => state.payment);
  const { isTrial, isExtend } = dataPaymentJob;

  const _onCloseModal = () => {
    onClose();
  };

  const getQrCode = () => {
    switch (true) {
      case pathname === APP_ROUTES.CREATE_REQUEST.path:
        return getLocalStorage(STORAGE_KEY.QR_CODE_SEARCH_FEE);
      case pathname === APP_ROUTES.JOB.PAYMENT.to && isTrial:
        return getLocalStorage(STORAGE_KEY.QR_CODE_TRIAL_JOB);
      case pathname === APP_ROUTES.JOB.PAYMENT.to && isExtend:
        return getLocalStorage(STORAGE_KEY.QR_CODE_TOP_UP);
      default:
        return valueQrCode;
    }
  };

  return (
    <PaymentModalWrapper
      open
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          padding: 4,
          width: isMethodPayNow ? '320px' : '400px',
          height: isMethodPayNow ? '420px' : 'auto',
        },
      }}
      onClose={!isMethodPayNow && _onCloseModal}
      {...rest}
    >
      <DialogTitle padding={0}>
        <Typography className="title-modal">
          {isMethodPayNow ? 'PayNow' : 'Add Debit/Credit Card'}
        </Typography>
        <CloseCircle
          onClick={_onCloseModal}
          sx={{ color: theme.palette.grey1.dark }}
          cursor={'pointer'}
        />
      </DialogTitle>
      <DialogContent>
        {isMethodPayNow ? (
          <PaymentPayNow valueQrCode={getQrCode()} />
        ) : (
          <PaymentStripe />
        )}
      </DialogContent>
    </PaymentModalWrapper>
  );
};

export default PaymentModal;
