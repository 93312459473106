import { PARAMS_REQUEST_DETAIL } from './create-request';

/* =========== ENUM ============ */
export enum CARD_TYPE {
  VISA = 'visa',
  MASTER_CARD = 'master-card',
  USA_EXPRESS = 'america-express',
}

export enum PAYMENT_MODAL_TYPE {
  PAY_NOW = 'paynow',
  CARD = 'card',
  ADD_NEW_CARD = 'ADD_NEW_CARD',
}

export enum PAYMENT_TYPE {
  SEARCH_FEE = 'search-fee',
  TRIAL_JOB = 'trial-job',
  AC_JOB_NOT_ENOUGH_BALANCE = 'ac-job-not-enough-balance',
  AC_JOB_ENOUGH_BALANCE = 'ac-job-enough-balance',
  PURCHASE_PACKAGE = 'purchase-package',
}

/* =========== INTERFACE ============ */
export interface CREDIT_BALANCE_RESPONSE {
  parent_id: string;
  balance: number;
  is_trial: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface CARD_LIST_RESPONSE {
  id: string;
  last4: string;
  brand: CARD_TYPE;
}

export interface SEARCH_FEE_PAYLOAD extends PARAMS_REQUEST_DETAIL {
  payment_method_id?: string; // for card + stripe
  payment_method_type: PAYMENT_MODAL_TYPE;
  // transaction_type: 'AC Search Fee';
}

export interface CHECKOUT_SEARCH_FEE_PAYLOAD {
  payment_method_type: PAYMENT_MODAL_TYPE;
}

export interface PURCHASE_PACKAGE_PAYLOAD {
  payment_method_id?: string; // for card + stripe
  payment_method_type: PAYMENT_MODAL_TYPE;
  package_id: string;
}

export interface PAY_JOB_PAYLOAD {
  payment_method_id?: string; // for card + stripe
  payment_method_type?: PAYMENT_MODAL_TYPE;
  job_id: string;
}

export interface AC_JOB_PAYLOAD {
  job_id: string;
}

export interface PAYMENT_RESPONSE {
  id: string;
  amount: number;
  currency: string;
  status: string;
  client_secret: string;
  next_action: {
    paynow_display_qr_code: PAY_NOW_QRCODE;
    type: string;
  };
}

export interface PAY_NOW_QRCODE {
  data: string;
  hosted_instructions_url: string;
  image_url_png: string;
  image_url_svg: string;
}

export interface ATTACH_PAYMENT_METHOD_PAYLOAD {
  payment_method_id: string;
}

export interface WEB_HOOK_PAYMENT_RESPONSE {
  amount: string;
  parent_id: string;
  payment_intent_id: string;
}

export interface CHECKOUT_PAYMENT_RESPONSE {
  amount: number;
  tax_amount: number;
  total_amount: number;
  balance: number;
  is_can_purchase_package: boolean;
  weekend_surcharge: SURCHARGE_ITEM_DATA;
  odd_hour_surcharge: SURCHARGE_ITEM_DATA;
  additional_child_surcharge: SURCHARGE_ITEM_DATA;
  payment_gateway_fee?: SURCHARGE_ITEM_DATA;
}

export interface SURCHARGE_ITEM_DATA {
  amount: number;
  percentage: number;
  title: string;
}
