import { setToken } from 'config/configApi';
import { STORAGE_KEY } from 'constants/global';
import { useDebounce } from 'hooks';
import { CHOOSE_ROLE_RESPONSE, USER_TYPE } from 'models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch } from 'store/hook';
import { setLoadingAuth } from 'store/reducers/auth';
import { getUserProfileAction } from 'store/reducers/auth/actionTypes';
import {
  clearLocalStorage,
  getLocalStorage,
  isUndefined,
  setLocalStorage,
} from 'utils';
import createContext from 'utils/createContext';

interface AuthContextValues {
  isLoggedIn: boolean;
  authRedirect: React.MutableRefObject<boolean>;
  alreadyCreated: boolean;
  login: (data: CHOOSE_ROLE_RESPONSE, alreadyCreated: boolean) => void;
  logout: () => void;
}

const [Provider, useAuth] = createContext<AuthContextValues>({
  name: 'auth',
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch();

  const authRedirect = useRef(true);
  const loginCallback = useRef<() => void>();
  const [alreadyCreated, setAlreadyCreate] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState(
    getLocalStorage(STORAGE_KEY.ACCESS_TOKEN),
  );
  const [refreshToken, setRefreshToken] = useState(
    getLocalStorage(STORAGE_KEY.REFRESH_TOKEN),
  );

  const checkIsTokenAvailable = () => {
    if (!isUndefined(accessToken) && !isUndefined(refreshToken)) return true;
    return false;
  };

  const isLoggedIn = useMemo(() => {
    return checkIsTokenAvailable();
    // eslint-disable-next-line
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (!isLoggedIn) return;
    loginCallback.current?.();
    loginCallback.current = undefined;
  }, [isLoggedIn]);

  const login = (data: CHOOSE_ROLE_RESPONSE, alreadyCreated: boolean) => {
    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);

    // This function to check Parent has created Base Request yet
    setAlreadyCreate(alreadyCreated);

    setToken(data.access_token);

    setLocalStorage(STORAGE_KEY.ACCESS_TOKEN, data.access_token);
    setLocalStorage(STORAGE_KEY.REFRESH_TOKEN, data.refresh_token);
    setLocalStorage(STORAGE_KEY.IS_CREATED_REQUEST, String(alreadyCreated));
  };

  const logout = () => {
    clearLocalStorage();
    const _window: any = window;
    _window.location = APP_ROUTES.AUTH.LOGIN.to;
  };

  const fetchAuth = useDebounce(() => {
    !loginCallback.current && dispatch(setLoadingAuth(true));

    if (!isLoggedIn) {
      !loginCallback.current && dispatch(setLoadingAuth(false));
    } else {
      dispatch(
        getUserProfileAction({
          data: {
            userType: USER_TYPE.PARENT,
          },
          onFailed: () => {
            logout();
          },
          onFinally: () =>
            !loginCallback.current && dispatch(setLoadingAuth(false)),
        }),
      );
    }
  }, 50);

  useEffect(() => {
    fetchAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedIn]);

  const value = useMemo(() => {
    return {
      isLoggedIn,
      authRedirect,
      alreadyCreated,
      login,
      logout,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return <Provider value={value}>{children}</Provider>;
};

export { AuthProvider, useAuth };
