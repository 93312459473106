import { Stack, Typography } from '@mui/material';
import {
  CHECKOUT_PAYMENT_RESPONSE,
  SURCHARGE_ITEM_DATA,
} from 'models/payments';
import { formatPrice } from 'utils';

interface SurchargeListProps {
  checkoutData: CHECKOUT_PAYMENT_RESPONSE;
  isShowPaymentGatewayFee: boolean;
}

const SurchargeItem = (props: SURCHARGE_ITEM_DATA) => {
  const { title, percentage, amount } = props;

  return (
    <Stack>
      <Typography variant="Components_Column_content" color="#6d6d6d">
        {title} ({percentage}%)
      </Typography>
      <Typography variant="Components_Column_content" color="#6d6d6d">
        {formatPrice(amount)}
      </Typography>
    </Stack>
  );
};

export const SurchargeList = ({
  checkoutData,
  isShowPaymentGatewayFee,
}: SurchargeListProps) => {
  return (
    <>
      {checkoutData?.additional_child_surcharge && (
        <SurchargeItem {...checkoutData.additional_child_surcharge} />
      )}
      {checkoutData?.weekend_surcharge && (
        <SurchargeItem {...checkoutData.weekend_surcharge} />
      )}
      {checkoutData?.odd_hour_surcharge && (
        <SurchargeItem {...checkoutData.odd_hour_surcharge} />
      )}
      {isShowPaymentGatewayFee && checkoutData?.payment_gateway_fee && (
        <SurchargeItem {...checkoutData.payment_gateway_fee} />
      )}
    </>
  );
};
