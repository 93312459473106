import { MainLayout } from 'components';
import { Helmet } from 'react-helmet-async';
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom';
import PrivateRoute from 'routers/PrivateRoute';
import { APP_ROUTES } from 'routers/routes';
import AuthFeature from './auth';
import EditBooking from './bookings/pages/EditJob';
import JobDetail from './bookings/pages/JobDetail';
import ListJobs from './bookings/pages/JobList';
import PaymentJobs from './bookings/pages/PaymentJobs';
import CreateRequest from './create-request';
import BuyCredits from './credits/pages/BuyCredits';
import OverviewCredits from './credits/pages/OverviewCredits';
import PaymentCredits from './credits/pages/PaymentCredits';
import HowItWorks from './how-it-works';
import Overview from './overview';
import { EditProfile } from './profile';
import SitterDetail from './sitter-detail';

const configRoutes: RouteObject[] = [
  {
    path: APP_ROUTES.AUTH.path,
    element: <AuthFeature />,
  },
  {
    path: APP_ROUTES.SITTER_DETAIL.path,
    element: (
      <PrivateRoute>
        <SitterDetail />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    element: (
      <PrivateRoute>
        <MainLayout />
        <Helmet>
          <title>Aunty Confinement</title>
          <meta name="description" content="Aunty Confinement" />
        </Helmet>
      </PrivateRoute>
    ),
    children: [
      {
        path: APP_ROUTES.HOW_IT_WORKS.path,
        element: <HowItWorks />,
      },
      {
        path: APP_ROUTES.CREATE_REQUEST.path,
        element: <CreateRequest />,
      },
      {
        path: APP_ROUTES.OVERVIEW.path,
        element: <Overview />,
      },
      {
        path: APP_ROUTES.JOB.path,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <ListJobs />,
          },
          {
            path: APP_ROUTES.JOB.DETAIL.path,
            element: <JobDetail />,
          },
          {
            path: APP_ROUTES.JOB.EDIT.path,
            element: <EditBooking />,
          },
          {
            path: APP_ROUTES.JOB.PAYMENT.path,
            element: <PaymentJobs />,
          },
          {
            path: '*',
            element: <Navigate to={APP_ROUTES.JOB.to} replace />,
          },
        ],
      },
      {
        path: APP_ROUTES.CREDITS.path,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <OverviewCredits />,
          },
          {
            path: APP_ROUTES.CREDITS.BUY_PACKAGE.path,
            element: <BuyCredits />,
          },
          {
            path: APP_ROUTES.CREDITS.PAYMENT.path,
            element: <PaymentCredits />,
          },
          {
            path: '*',
            element: <Navigate to={APP_ROUTES.CREDITS.to} replace />,
          },
        ],
      },
      {
        path: APP_ROUTES.PROFILE.path,
        element: <EditProfile />,
      },
      {
        path: '*',
        element: <Navigate to={APP_ROUTES.JOB.to} replace />,
      },
    ],
  },
];

function ParentFeature() {
  const routes = useRoutes(configRoutes);
  return <>{routes}</>;
}

export default ParentFeature;
