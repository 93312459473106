import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import {
  GET_LIST_JOB_PARAMS,
  JOB_LIST_RESPONSE,
  PayloadActionData,
  RESPONSE_TYPE,
  UPDATE_JOB_PAYLOAD,
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { jobApi } from 'services';
import { setGlobalLoading } from 'store/reducers/global';
import {
  updateJobDetail,
  updateJobItemInList,
  updateJobList,
} from 'store/reducers/job';
import {
  CANCEL_JOB_REQUEST,
  GET_JOB_DETAIL_REQUEST,
  GET_JOB_LIST_REQUEST,
  GET_PREVIEW_EXTEND_JOB_REQUEST,
  UPDATE_JOB_REQUEST,
} from 'store/reducers/job/actionTypes';

function* getBookingListRequest(
  action: PayloadAction<PayloadActionData<GET_LIST_JOB_PARAMS>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<JOB_LIST_RESPONSE>> =
      yield call(jobApi.getList, action.payload.data);
    action.payload.onSuccess?.();
    yield put(updateJobList(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* getBookingDetailRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<any>> = yield call(
      jobApi.getDetail,
      action.payload.data.id,
    );
    yield put(updateJobDetail(response.data.data));
    yield put(updateJobItemInList(response.data.data));
    action.payload.onSuccess?.();
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* updateJobRequest(
  action: PayloadAction<PayloadActionData<UPDATE_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const { id, ...rest } = action.payload.data;
    const response: AxiosResponse<RESPONSE_TYPE<any>> = yield call(
      jobApi.update,
      rest,
      id,
    );
    action.payload.onSuccess?.();
    yield put(updateJobDetail(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* getPreviewExtendJobRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<any>> = yield call(
      jobApi.getPreviewExtend,
      action.payload.data.id,
    );
    action.payload.onSuccess?.(response.data.data);
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* cancelJobRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<any>> = yield call(
      jobApi.cancel,
      action.payload.data.id,
    );
    action.payload.onSuccess?.();
    yield put(updateJobDetail(response.data.data));
    yield put(updateJobItemInList(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* jobSaga() {
  yield takeLatest(GET_JOB_LIST_REQUEST, getBookingListRequest);
  yield takeLatest(GET_JOB_DETAIL_REQUEST, getBookingDetailRequest);
  yield takeLatest(UPDATE_JOB_REQUEST, updateJobRequest);
  yield takeLatest(CANCEL_JOB_REQUEST, cancelJobRequest);
  yield takeLatest(GET_PREVIEW_EXTEND_JOB_REQUEST, getPreviewExtendJobRequest);
}
export default jobSaga;
