import styled from '@emotion/styled';
import { Alert, Box } from '@mui/material';
import { theme } from 'theme';

export const ProfileWrapper = styled(Box)`
  max-width: 530px;
  margin: auto;
`;

export const AlertStyled = styled(Alert)`
  background-color: rgba(255, 121, 110, 0.04);
  border-color: ${theme.palette.primary.main};
  color: ${theme.palette.black.dark};
  & .MuiSvgIcon-root {
    color: ${theme.palette.primary.main};
  }
`;
