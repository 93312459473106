import { JOB_STATUS } from 'models';

export const mapBookingTitle = {
  [JOB_STATUS.PENDING]: 'Payment Pending',
  [JOB_STATUS.UPCOMING]: 'Upcoming',
  [JOB_STATUS.STARTED]: 'Started',
  [JOB_STATUS.PENDING_COMPLETED]: 'Pending Complete',
  [JOB_STATUS.CANCELLED]: 'Canceled',
  [JOB_STATUS.COMPLETED]: 'Completed',
  [JOB_STATUS.UPCOMING_PAID]: 'Upcoming Paid',
  [JOB_STATUS.UPCOMING_INSUFFICIENT]: 'Upcoming Insufficient',
};

export const mapBookingColor = {
  [JOB_STATUS.PENDING]: 'primary.main',
  [JOB_STATUS.UPCOMING]: 'secondary.main',
  [JOB_STATUS.STARTED]: 'primary.main',
  [JOB_STATUS.UPCOMING_PAID]: 'primary.main',
  [JOB_STATUS.UPCOMING_INSUFFICIENT]: 'primary.main',
  [JOB_STATUS.PENDING_COMPLETED]: 'primary.main',
  [JOB_STATUS.CANCELLED]: 'primary.main',
  [JOB_STATUS.COMPLETED]: 'primary.main',
};

export const mapButtonTitle = {
  [JOB_STATUS.PENDING]: 'Make Payment',
  [JOB_STATUS.UPCOMING]: 'Make Payment',
  [JOB_STATUS.STARTED]: '',
  [JOB_STATUS.UPCOMING_PAID]: 'Edit Booking',
  [JOB_STATUS.UPCOMING_INSUFFICIENT]: 'Make Payment',
  [JOB_STATUS.PENDING_COMPLETED]: 'Make Payment',
  [JOB_STATUS.CANCELLED]: 'Canceled',
  [JOB_STATUS.COMPLETED]: 'Complete',
};
