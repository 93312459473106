import { Stack, Typography } from '@mui/material';
import ScrollToLoadMore from 'components/common/ScrollLoadMore';
import TransactionItem from 'components/common/TransactionItem';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  getCurrentBalanceCreditAction,
  getSubscriptionPurchasedAction,
} from 'store/reducers/credits/actionTypes';
import {
  BuyCreditsButton,
  StyledCurrentBalanceTitle,
  StyledCurrentBalanceValue,
  StyledOverviewCredits,
} from './styles';

const OverviewCredits = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentBalance, subsPurchased } = useAppSelector(
    (state) => state.credits,
  );
  const { parent } = useAppSelector((state) => state.auth);

  const loadMoreTransaction = () => {
    dispatch(
      getSubscriptionPurchasedAction({
        data: {
          limit: 10,
          page: subsPurchased.metadata.page + 1,
          has_balance: true,
          parent_id: parent?.parent_id,
        },
      }),
    );
  };

  const handleBuyPackage = () => {
    navigate(APP_ROUTES.CREDITS.BUY_PACKAGE.to);
  };

  useEffect(() => {
    dispatch(
      getCurrentBalanceCreditAction({
        data: {
          parentId: parent?.parent_id,
        },
      }),
    );
    dispatch(
      getSubscriptionPurchasedAction({
        data: {
          limit: 10,
          page: 1,
          has_balance: true,
          parent_id: parent?.parent_id,
        },
      }),
    );
    // eslint-disable-next-line
  }, [dispatch]);

  if (!subsPurchased) return;
  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="Web_Title_22">Credits</Typography>
        <Typography variant="Web_Label_18">
          Manage your booking credits
        </Typography>
      </Stack>
      <StyledOverviewCredits>
        <Stack spacing={0}>
          <StyledCurrentBalanceTitle>Current Balance</StyledCurrentBalanceTitle>
          <StyledCurrentBalanceValue>
            ~${currentBalance}
          </StyledCurrentBalanceValue>
        </Stack>
        <BuyCreditsButton variant="contained" onClick={handleBuyPackage}>
          Buy Credits
        </BuyCreditsButton>
      </StyledOverviewCredits>
      <ScrollToLoadMore
        data={subsPurchased.records}
        metadata={subsPurchased.metadata}
        loadMore={loadMoreTransaction}
      >
        <Stack spacing={2}>
          {subsPurchased.records.length
            ? subsPurchased.records.map((subscription) => (
                <TransactionItem key={subscription.id} data={subscription} />
              ))
            : 'You don’t have any transactions yet.'}
        </Stack>
      </ScrollToLoadMore>
    </Stack>
  );
};

export default OverviewCredits;
