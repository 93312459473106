import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import {
  GET_LIST_JOB_PARAMS,
  JOB_DETAIL_RESPONSE,
  JOB_LIST_RESPONSE,
  RESPONSE_TYPE,
  UPDATE_JOB_PAYLOAD,
} from 'models';

export const jobApi = {
  getList: async (params: GET_LIST_JOB_PARAMS) => {
    return APIInstance.get<RESPONSE_TYPE<JOB_LIST_RESPONSE>>(
      `${API.JOB.LIST}`,
      {
        params,
      },
    );
  },
  getDetail: async (id: string) => {
    return APIInstance.get<RESPONSE_TYPE<JOB_DETAIL_RESPONSE>>(
      `${API.JOB.DETAIL(id)}`,
    );
  },
  update: async (data: UPDATE_JOB_PAYLOAD, id: string) => {
    return APIInstance.patch(`${API.JOB.UPDATE(id)}`, data);
  },
  cancel: async (id: string) => {
    return APIInstance.patch(`${API.JOB.CANCEL(id)}`);
  },
  getPreviewExtend: async (id: string) => {
    return APIInstance.get<RESPONSE_TYPE<any>>(`${API.JOB.PREVIEW_EXTEND(id)}`);
  },
};
