import { createTheme } from '@mui/material/styles';
import ComponentsOverrides from './overrides';
import './theme.model';
import { typography } from './typography';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: 'rgba(255, 121, 110, 0.20)',
      main: '#FF796E',
      dark: '#E56D63 ',
      contrastText: '#fff',
    },
    primary_shades: {
      4: 'rgba(255, 121, 110, 0.04)',
      8: 'rgba(255, 121, 110, 0.08)',
      20: 'rgba(255, 121, 110, 0.2)',
      50: 'rgba(255, 121, 110, 0.5)',
    },
    secondary: {
      light: 'rgba(34, 111, 227, 0.20)',
      main: '#226FE3',
      dark: '#1F64CC',
      contrastText: '#fff',
    },
    secondary_shades: {
      4: 'rgba(34, 111, 227, 0.04)',
      8: 'rgba(34, 111, 227, 0.08)',
      20: 'rgba(34, 111, 227, 0.2)',
      30: 'rgba(34, 111, 227, 0.3)',
      50: 'rgba(34, 111, 227, 0.5)',
    },
    background: {
      light: '#FAFAFA',
      dark: '#EEECEC',
      default: '#F5F4F4',
    },
    black: {
      light: '#000000',
      dark: '#000000',
    },
    white: {
      light: '#FFFFFF',
      dark: '#FFFFFF',
    },
    blue: {
      light: '#007AFF',
      dark: '#0A84FF',
    },
    green: {
      light: '#34C759',
      dark: '#32D74B',
    },
    red: {
      light: '#FF3B30',
      dark: '#FF453A',
    },
    yellow: {
      light: '#FFCC00',
      dark: '#FFD60A',
    },
    grey1: {
      light: '#8E8E93',
      dark: '#959595',
    },
    grey2: {
      light: '#AEAEB2',
      dark: '#666',
    },
    grey3: {
      light: 'rgba(60, 60, 67, 0.60)',
      dark: 'rgba(0, 0, 0, 0.60)',
    },
    grey4: {
      light: '#D1D1D6',
      dark: '#3A3A3C',
    },
    grey5: {
      light: '#B6B6B6',
      dark: '#2C2C2E',
    },
    grey6: {
      light: '#F2F2F7',
      dark: '#1C1C1E',
    },
    light: {
      stroke: '#00000014',
      placeholder: '#B6B6B6',
      shades4: '#FF796E0A',
      shades8: '#FF796E14',
      secondary: '#3C3C4399',
      quaternary: '#959595',
      tertiary: '#666666',
    },
    label: {
      primary: '#000000',
      secondary: 'rgba(60, 60, 67, 0.6)',
      tertiary: 'gba(60, 60, 67, 0.33)',
      quarternary: 'rgba(60, 60, 67, 0.18)',
    },
    seperators: {
      non_opaque: '#CECED1',
      opaque: 'rgba(206, 206, 209, 0.4)',
    },
    text: {
      primary: '#000000',
      secondary: '#545454',
      disabled: '#B7B7B7',
    },
    other: {
      stroke: 'rgba(0, 0, 0, 0.08)',
      disable_shades_50: 'rgba(183, 183, 183, 0.5)',
    },
    backgrounds: {
      primary: '#FAFAFA',
      tertiary: '#EEECEC',
    },
  },
  typography,
});
theme.components = ComponentsOverrides(theme);

export default theme;
