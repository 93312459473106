import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Stack,
  SxProps,
} from '@mui/material';
import { ChevronDownIcon } from 'assets';
import { DATE_TIME_FORMAT } from 'constants/date';
import { ReactNode, useState } from 'react';
import TimePickerProvider, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Control, useController } from 'react-hook-form';
import { StyledInput } from './styles';

interface TimePickerFieldProps extends Omit<ReactDatePickerProps, 'onChange'> {
  label?: string | ReactNode;
  control?: Control<any>;
  hideHelper?: boolean;
  errorMess?: string;
  rootProps?: Partial<FormControlProps>;
  onChange?: () => void;
  sx?: SxProps;
}

function TimePickerField({
  label,
  name,
  rootProps,
  control,
  hideHelper,
  errorMess,
  selected: externalValue,
  onChange: externalOnChange,
  ...rest
}: TimePickerFieldProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = control
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useController({ name, control })
    : {
        field: {
          value: externalValue,
          onChange: externalOnChange as (...event: any[]) => void,
          onBlur: undefined,
          ref: undefined,
        },
        fieldState: { error: undefined },
      };

  const [open, setOpen] = useState<boolean>(false);

  return (
    <FormControl {...rootProps} fullWidth>
      {label && (
        <Stack flexDirection="row" alignItems="center">
          <FormLabel htmlFor={name}>{label}</FormLabel>
        </Stack>
      )}
      <TimePickerProvider
        {...rest}
        open={open}
        ref={ref}
        onBlur={onBlur}
        selected={value}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        onCalendarClose={() => setOpen(false)}
        onCalendarOpen={() => setOpen(true)}
        dateFormat={DATE_TIME_FORMAT.DEFAULT_TIME}
        onChange={(date: Date) => onChange(date)}
        customInput={
          <StyledInput
            $disabled={rest.disabled}
            readOnly
            endAdornment={
              <ChevronDownIcon
                onClick={() => setOpen(!open)}
                sx={{ transform: open ? 'rotate(-180deg)' : 'unset' }}
              />
            }
          />
        }
      />
      {!hideHelper && (error || errorMess) && (
        <FormHelperText error={!!error || !!errorMess}>
          {error?.message || errorMess}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default TimePickerField;
