import styled from '@emotion/styled';
import { Button, Stack } from '@mui/material';
import { InputField } from 'components';
import InlineError from 'components/common/ErrorInline';
import { SYS_MESS } from 'constants/systemMessage';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { updateBodyCreateRequest } from 'store/reducers/trial-request';

const UserSignWrapper = styled(Stack)(() => ({
  flexDirection: 'column',
  gap: 5,
  '& .inputSign': {
    height: 80,
  },
  '& .placeholder': {
    position: 'absolute',
    top: '50%',
    left: 20,
    transform: 'translateY(-50%)',
  },
  '& .buttonSubmit': {
    height: 40,
    marginRight: 'auto',
  },
  '& .MuiTypography-root': {
    paddingBottom: 5,
  },
}));

interface YourSignatureProps {
  isBottom: boolean;
  onSubmitCallback: () => void;
}

const UserSign = ({ isBottom, onSubmitCallback }: YourSignatureProps) => {
  const dispatch = useAppDispatch();
  const { bodyCreateRequest } = useAppSelector((state) => state.trialRequest);
  const [valueSign, setValueSign] = useState<string | null>(null);
  const [isFirstOpen, setIsFirstOpen] = useState<boolean>(true);

  // const sigRef = useRef<SignatureCanvas>();

  // const handleEndSignature = (): void => {
  //   if (!sigRef.current) return;
  //   setValueSign(sigRef.current.toDataURL());
  // };

  // const _clearSignature = (): void => {
  //   if (!sigRef.current) return;
  //   sigRef.current.clear();
  //   setValueSign(null);
  // };

  const isHasError = useMemo(() => {
    return valueSign === '';
  }, [valueSign]);

  const onSubmit = () => {
    setIsFirstOpen(false);
    if (valueSign) {
      onSubmitCallback();
      dispatch(
        updateBodyCreateRequest({
          ...bodyCreateRequest,
          yourSignature: valueSign,
        }),
      );
    } else {
      setValueSign('');
    }
  };

  return (
    <UserSignWrapper>
      <InputField
        placeholder="Type your signature"
        value={valueSign}
        onChange={(e) => setValueSign(e.target.value)}
        className="inputSign"
      />
      <InlineError
        open={!isFirstOpen && isHasError}
        error={SYS_MESS.ERROR.COMMON.FILL_DATA}
      />
      <Button
        variant="contained"
        onClick={onSubmit}
        className="buttonSubmit"
        disabled={!isBottom}
        sx={{ width: { xs: '100px', sm: '300px' } }}
      >
        Submit
      </Button>
    </UserSignWrapper>
  );
};

export default UserSign;
