import { SvgIcon, SvgIconProps } from '@mui/material';

export function ArrowStrokeIcon({ sx = {}, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="25"
      height="16"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ ...sx, fill: 'none' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3431 0.928635L24.7071 7.2926C25.0976 7.68312 25.0976 8.31629 24.7071 8.70681L18.3431 15.0708C17.9526 15.4613 17.3195 15.4613 16.9289 15.0708C16.5384 14.6802 16.5384 14.0471 16.9289 13.6566L21.5858 8.9997H0V6.9997H21.5858L16.9289 2.34285C16.5384 1.95232 16.5384 1.31916 16.9289 0.928635C17.3195 0.538111 17.9526 0.538111 18.3431 0.928635Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default ArrowStrokeIcon;
