import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { StyledLogoIcon } from '../../styles';

export const LogoAuth = () => {
  return (
    <Stack alignItems="center" mb={6}>
      <Link to={APP_ROUTES.AUTH.LOGIN.to}>
        <StyledLogoIcon />
      </Link>
    </Stack>
  );
};

export default LogoAuth;
