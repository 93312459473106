import { PayloadActionData } from 'models';
import {
  AC_JOB_PAYLOAD,
  ATTACH_PAYMENT_METHOD_PAYLOAD,
  CHECKOUT_SEARCH_FEE_PAYLOAD,
  PAY_JOB_PAYLOAD,
  PURCHASE_PACKAGE_PAYLOAD,
  SEARCH_FEE_PAYLOAD,
} from 'models/payments';

export const PAY_FOR_SEARCH_FEE_REQUEST = 'PAY_FOR_SEARCH_FEE_REQUEST';
export const PAY_FOR_BUY_PACKAGE_REQUEST = 'PAY_FOR_BUY_PACKAGE_REQUEST';
export const ATTACH_PAYMENT_METHOD_REQUEST = 'ATTACH_PAYMENT_METHOD_REQUEST';
export const PAY_FOR_TRIAL_JOB_REQUEST = 'PAY_FOR_TRIAL_JOB_REQUEST';
export const PAY_FOR_EXTEND_TRIAL_JOB_REQUEST =
  'PAY_FOR_EXTEND_TRIAL_JOB_REQUEST';
export const PAY_FOR_NORMAL_JOB_REQUEST = 'PAY_FOR_NORMAL_JOB_REQUEST';
export const PAY_FOR_EXTEND_JOB_REQUEST = 'PAY_FOR_EXTEND_JOB_REQUEST';
export const PAY_JOB_BY_TOP_UP_REQUEST = 'PAY_JOB_BY_TOP_UP_REQUEST';
export const PAY_EXTEND_JOB_BY_TOP_UP_REQUEST =
  'PAY_EXTEND_JOB_BY_TOP_UP_REQUEST';
export const GET_LIST_CARD_REQUEST = 'GET_LIST_CARD_REQUEST';
export const CHECKOUT_TRIAL_JOB_REQUEST = 'CHECKOUT_TRIAL_JOB_REQUEST';
export const CHECKOUT_SEARCH_FEE_REQUEST = 'CHECKOUT_SEARCH_FEE_REQUEST';
export const CHECKOUT_AC_JOB_REQUEST = 'CHECKOUT_AC_JOB_REQUEST';
export const CHECKOUT_PURCHASE_PACKAGE_REQUEST =
  'CHECKOUT_PURCHASE_PACKAGE_REQUEST';
export const CHECKOUT_EXTEND_JOB_REQUEST = 'CHECKOUT_EXTEND_JOB_REQUEST';

export const getListCardsAction = () => ({
  type: GET_LIST_CARD_REQUEST,
});

export const attachPaymentMethodAction = (
  payload: PayloadActionData<ATTACH_PAYMENT_METHOD_PAYLOAD>,
) => ({
  type: ATTACH_PAYMENT_METHOD_REQUEST,
  payload,
});

export const getFeeSummaryAction = () => ({
  type: ATTACH_PAYMENT_METHOD_REQUEST,
});

export const paySearchFeeAction = (
  payload: PayloadActionData<SEARCH_FEE_PAYLOAD>,
) => ({
  type: PAY_FOR_SEARCH_FEE_REQUEST,
  payload,
});

export const payForBuyPackageAction = (
  payload: PayloadActionData<PURCHASE_PACKAGE_PAYLOAD>,
) => ({
  type: PAY_FOR_BUY_PACKAGE_REQUEST,
  payload,
});

export const payForTrialJobAction = (
  payload: PayloadActionData<PAY_JOB_PAYLOAD>,
) => ({
  type: PAY_FOR_TRIAL_JOB_REQUEST,
  payload,
});

export const payForExtendTrialJobAction = (
  payload: PayloadActionData<PAY_JOB_PAYLOAD>,
) => ({
  type: PAY_FOR_EXTEND_TRIAL_JOB_REQUEST,
  payload,
});

export const payForNormalJobAction = (
  payload: PayloadActionData<AC_JOB_PAYLOAD>,
) => ({
  type: PAY_FOR_NORMAL_JOB_REQUEST,
  payload,
});

export const payForExtendJobAction = (
  payload: PayloadActionData<AC_JOB_PAYLOAD>,
) => ({
  type: PAY_FOR_EXTEND_JOB_REQUEST,
  payload,
});

export const payJobByTopUpAction = (
  payload: PayloadActionData<PAY_JOB_PAYLOAD>,
) => ({
  type: PAY_JOB_BY_TOP_UP_REQUEST,
  payload,
});

export const payExtendJobByTopUpAction = (
  payload: PayloadActionData<PAY_JOB_PAYLOAD>,
) => ({
  type: PAY_EXTEND_JOB_BY_TOP_UP_REQUEST,
  payload,
});

export const checkoutTrialJobAction = (
  payload: PayloadActionData<PAY_JOB_PAYLOAD>,
) => ({
  type: CHECKOUT_TRIAL_JOB_REQUEST,
  payload,
});

export const checkoutACJobAction = (
  payload: PayloadActionData<PAY_JOB_PAYLOAD>,
) => ({
  type: CHECKOUT_AC_JOB_REQUEST,
  payload,
});

export const checkoutExtendJobAction = (
  payload: PayloadActionData<PAY_JOB_PAYLOAD>,
) => ({
  type: CHECKOUT_EXTEND_JOB_REQUEST,
  payload,
});

export const checkoutSearchFeeAction = (
  payload: PayloadActionData<CHECKOUT_SEARCH_FEE_PAYLOAD>,
) => ({
  type: CHECKOUT_SEARCH_FEE_REQUEST,
  payload,
});

export const checkoutPurchasePackageAction = (
  payload: PayloadActionData<PURCHASE_PACKAGE_PAYLOAD>,
) => ({
  type: CHECKOUT_PURCHASE_PACKAGE_REQUEST,
  payload,
});
