import {
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { APP_IMG } from 'assets';
import { PopupConfirm, TYPE_ICON } from 'components/popup';
import PopupBuyCredits from 'components/popup/PopupBuyCredits';
import { PAYMENT_MODAL_TYPE, PAYMENT_TYPE } from 'models/payments';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import {
  setOpenBuyCredits,
  setOpenPaymentModal,
  updatePaymentMethodId,
} from 'store/reducers/payments';
import { getListCardsAction } from 'store/reducers/payments/actionTypes';
import { formatPrice, getLocalStorage, setLocalStorage, sleep } from 'utils';
import { PaymentModal } from './components';
import CreditBalanceView from './components/CreditBalanceView';
import { SurchargeList } from './components/SurchargeList';
import {
  FeeSummaryContainer,
  PaymentContainer,
  PaymentMethodContainer,
  PaymentTitle,
  StyledAddCard,
  StyledFormControl,
  TotalAmount,
} from './styles';

interface PaymentComponentProps {
  moreFeeChildren: ReactNode;
  hideTotal?: boolean;
  handleBackAction: () => void;
  paymentCallback: (isPayNow: boolean, callback?: Function) => void;
  checkoutAction?: (isPayNow: boolean) => void;
  storageKey?: string;
  paymentType?: PAYMENT_TYPE;
}

interface PAYMENT_MODAL {
  type: PAYMENT_MODAL_TYPE;
  valueQrCode: string;
}

function PaymentComponent({
  hideTotal = false,
  moreFeeChildren,
  handleBackAction,
  paymentCallback,
  checkoutAction,
  storageKey,
  paymentType,
}: PaymentComponentProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openPaymentModal, openBuyCredits, listCards, checkoutData } =
    useAppSelector((state) => state.payment);
  const [paymentModalData, setPaymentModalData] = useState<PAYMENT_MODAL>({
    type: PAYMENT_MODAL_TYPE.PAY_NOW,
    valueQrCode: '',
  });
  const [value, setValue] = useState<string>(PAYMENT_MODAL_TYPE.PAY_NOW);
  const [isOpenInsufficientBalance, setIsOpenInsufficientBalance] =
    useState<boolean>(false);

  const isMethodPayNow = value === PAYMENT_MODAL_TYPE.PAY_NOW;
  const { balance, total_amount, is_can_purchase_package } = checkoutData;

  const displayBalance =
    PAYMENT_TYPE.AC_JOB_ENOUGH_BALANCE === paymentType ||
    PAYMENT_TYPE.AC_JOB_NOT_ENOUGH_BALANCE === paymentType;
  const displayPaymentMethod =
    isOpenInsufficientBalance ||
    !displayBalance ||
    (balance < total_amount && !is_can_purchase_package);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue !== PAYMENT_MODAL_TYPE.PAY_NOW) {
      dispatch(updatePaymentMethodId(selectedValue));
    }
    setValue(selectedValue);
    checkoutAction?.(selectedValue === PAYMENT_MODAL_TYPE.PAY_NOW);
  };

  const handlePayment = () => {
    if (
      isMethodPayNow &&
      getLocalStorage(storageKey) &&
      paymentType !== PAYMENT_TYPE.AC_JOB_ENOUGH_BALANCE
    ) {
      dispatch(setOpenPaymentModal(true));
      setPaymentModalData({
        type: PAYMENT_MODAL_TYPE.PAY_NOW,
        valueQrCode: getLocalStorage(storageKey),
      });
      return;
    }

    paymentCallback(isMethodPayNow, (valQR: string) => {
      storageKey && setLocalStorage(storageKey, valQR);
      dispatch(setOpenPaymentModal(true));
      setPaymentModalData({
        type: PAYMENT_MODAL_TYPE.PAY_NOW,
        valueQrCode: valQR,
      });
    });
  };

  const handleClickAddCard = () => {
    dispatch(setOpenPaymentModal(true));
    setPaymentModalData({
      type: PAYMENT_MODAL_TYPE.ADD_NEW_CARD,
      valueQrCode: '',
    });
  };

  const _onClosePaymentModal = () => {
    dispatch(setOpenPaymentModal(false));
    setPaymentModalData({
      type: PAYMENT_MODAL_TYPE.CARD,
      valueQrCode: '',
    });
  };

  useEffect(() => {
    checkoutAction?.(isMethodPayNow);
    if (paymentType !== PAYMENT_TYPE.AC_JOB_ENOUGH_BALANCE) {
      dispatch(getListCardsAction());
    }
    // eslint-disable-next-line
  }, [displayPaymentMethod]);

  useEffect(() => {
    if (
      !checkoutData &&
      [
        PAYMENT_TYPE.TRIAL_JOB,
        PAYMENT_TYPE.SEARCH_FEE,
        PAYMENT_TYPE.PURCHASE_PACKAGE,
      ].includes(paymentType)
    )
      if (balance < total_amount && !is_can_purchase_package) {
        setIsOpenInsufficientBalance(true);
      }

    // eslint-disable-next-line
  }, [balance]);

  if (!checkoutData) return;

  return (
    <PaymentContainer>
      {openPaymentModal && (
        <PaymentModal
          methodType={paymentModalData.type}
          onClose={_onClosePaymentModal}
          valueQrCode={paymentModalData.valueQrCode}
        />
      )}
      {isOpenInsufficientBalance && (
        <PopupConfirm
          typeIcon={TYPE_ICON.HELP}
          title="Insufficient balance"
          content={
            <Typography variant="Web_Label_14">
              You don’t have enough balance
              <br /> to pay this session.
            </Typography>
          }
          textButton="Top-up"
          textButton2="Back"
          handleBack={() => navigate(-1)}
          onSubmit={async () => {
            dispatch(setGlobalLoading(true));
            await sleep(500);
            dispatch(setGlobalLoading(false));
            setIsOpenInsufficientBalance(false);
          }}
          handleClose={() => setIsOpenInsufficientBalance(false)}
          justCloseWithBtn
        />
      )}
      <FeeSummaryContainer>
        <PaymentTitle>Fee Summary</PaymentTitle>
        {moreFeeChildren}

        <SurchargeList
          checkoutData={checkoutData}
          isShowPaymentGatewayFee={!isMethodPayNow}
        />

        {!hideTotal && (
          <Stack>
            <TotalAmount>Total Amount</TotalAmount>
            <TotalAmount>{formatPrice(total_amount)}</TotalAmount>
          </Stack>
        )}
      </FeeSummaryContainer>
      <Divider sx={{ borderColor: '#D9D9D9' }} />
      <PaymentMethodContainer>
        {displayBalance && (
          <CreditBalanceView
            creditBalance={balance}
            isInsufficientBalance={balance < total_amount}
            canPurchasePackage={is_can_purchase_package}
          />
        )}
        {displayPaymentMethod && (
          <>
            <Stack gap={2.25}>
              <PaymentTitle>Payment Method</PaymentTitle>
              <StyledFormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={PAYMENT_MODAL_TYPE.PAY_NOW}
                    control={<Radio />}
                    label={
                      <Stack className="payment-method-label">
                        <img src={APP_IMG.payNowIcon} alt="pay-now" />
                        <Typography>PayNow</Typography>
                      </Stack>
                    }
                  />
                  {listCards
                    ?.filter(
                      (card, index, self) =>
                        index === self.findIndex((t) => t.last4 === card.last4),
                    )
                    ?.map((card) => (
                      <FormControlLabel
                        key={card.id}
                        value={card.id}
                        control={<Radio />}
                        label={
                          <Stack className="payment-method-label">
                            <img src={APP_IMG.cardsIcon} alt="cards" />
                            <Typography>••••{card.last4}</Typography>
                          </Stack>
                        }
                      />
                    ))}
                </RadioGroup>
              </StyledFormControl>
            </Stack>
            <StyledAddCard onClick={handleClickAddCard}>
              <img src={APP_IMG.cardsIcon} alt="cards" />
              <Typography>+ Add Debit/Credit Card</Typography>
            </StyledAddCard>
          </>
        )}
      </PaymentMethodContainer>
      <Button
        variant="contained"
        fullWidth
        onClick={handlePayment}
        disabled={
          balance >= 0 && balance < total_amount && is_can_purchase_package
        }
      >
        Make Payment
      </Button>
      <Button variant="text" fullWidth onClick={handleBackAction}>
        Back
      </Button>
      {openBuyCredits && (
        <PopupBuyCredits
          _onClosePopup={() => dispatch(setOpenBuyCredits(false))}
        />
      )}
    </PaymentContainer>
  );
}

export default PaymentComponent;
