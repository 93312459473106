import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CARD_LIST_RESPONSE, CHECKOUT_PAYMENT_RESPONSE } from 'models/payments';

export interface PaymentType {
  openPaymentModal: boolean;
  openBuyCredits: boolean;
  paymentMethodId: string;
  paymentIntentId: string;
  openPaymentSuccess: boolean;
  dataPaymentJob: {
    total: number;
    id: string;
    isTrial: boolean;
    isExtend?: boolean;
  };
  listCards: CARD_LIST_RESPONSE[];
  checkoutData: CHECKOUT_PAYMENT_RESPONSE;
}

const initialState: PaymentType = {
  paymentMethodId: '',
  paymentIntentId: '',
  openPaymentModal: false,
  openBuyCredits: false,
  openPaymentSuccess: false,
  dataPaymentJob: {
    total: 0,
    id: '',
    isTrial: false,
    isExtend: false,
  },
  listCards: [],
  checkoutData: {
    amount: 0,
    tax_amount: 0,
    total_amount: 0,
    balance: 0,
    is_can_purchase_package: false,
    weekend_surcharge: null,
    odd_hour_surcharge: null,
    additional_child_surcharge: null,
    payment_gateway_fee: null,
  },
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setOpenPaymentModal(state: PaymentType, action: PayloadAction<boolean>) {
      state.openPaymentModal = action.payload;
    },
    setOpenBuyCredits(state: PaymentType, action: PayloadAction<boolean>) {
      state.openBuyCredits = action.payload;
    },
    setOpenPaymentSuccess(state: PaymentType, action: PayloadAction<boolean>) {
      state.openPaymentSuccess = action.payload;
    },
    updatePaymentMethodId(state: PaymentType, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload;
    },
    updatePaymentIntentId(state: PaymentType, action: PayloadAction<string>) {
      state.paymentIntentId = action.payload;
    },
    updateDataPaymentJob(
      state: PaymentType,
      action: PayloadAction<{
        total: number;
        id: string;
        isTrial: boolean;
        isExtend?: boolean;
      }>,
    ) {
      state.dataPaymentJob = action.payload;
    },
    updateListCards(
      state: PaymentType,
      action: PayloadAction<CARD_LIST_RESPONSE[]>,
    ) {
      state.listCards = action.payload;
    },
    updateDataCheckoutPayment(
      state: PaymentType,
      action: PayloadAction<CHECKOUT_PAYMENT_RESPONSE>,
    ) {
      state.checkoutData = action.payload;
    },
  },
});

// Actions
export const {
  setOpenPaymentModal,
  updatePaymentMethodId,
  updatePaymentIntentId,
  setOpenBuyCredits,
  setOpenPaymentSuccess,
  updateDataPaymentJob,
  updateListCards,
  updateDataCheckoutPayment,
} = paymentSlice.actions;

export default paymentSlice.reducer;
