import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';
import { DatePickerField } from 'components';
import TimePickerField from 'components/form-control/TimePickerField';
import { NotifyService } from 'config';
import yup from 'config/yup.custom';
import { SYS_MESS } from 'constants/systemMessage';
import { mapBookingTitle } from 'features/bookings/helpers';
import { EDIT_JOB_FORM, JOB_STATUS } from 'models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import { updateJobDetail } from 'store/reducers/job';
import {
  getJobDetailAction,
  updateJobAction,
} from 'store/reducers/job/actionTypes';
import {
  checkEqualDate,
  COMPARE_DATE,
  compareDateOnly,
  convertToLocalTime,
  convertToUtcTime,
  getMilBetweenDate,
  mergeDateTime,
} from 'utils';
import { EditBookingContainer, FormEditBookingContainer } from './styles';

const schema = yup
  .object()
  .shape({
    startDate: yup.mixed().required(SYS_MESS.ERROR.START_DATE_REQUIRED),
    endDate: yup.mixed().required(SYS_MESS.ERROR.END_DATE_REQUIRED),
    startTime: yup.mixed().required(SYS_MESS.ERROR.START_TIME_REQUIRED),
    endTime: yup
      .mixed()
      .required(SYS_MESS.ERROR.END_TIME_REQUIRED)
      .test('custom', SYS_MESS.ERROR.END_TIME_LARGE, (value, context) => {
        const { startDate, endDate, startTime } = context.parent;
        const resultCompare = compareDateOnly(startDate, endDate);
        const isEndTimeSmallest =
          getMilBetweenDate(new Date(startTime), new Date(value)) <
          2 * 60 * 60 * 1000; // 2 hours

        if (resultCompare === COMPARE_DATE.IS_SAME && isEndTimeSmallest) {
          return false;
        }
        return true;
      }),
  })
  .required();

function EditJob() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { jobDetail } = useAppSelector((state) => state.jobs);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const form = useForm<EDIT_JOB_FORM>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    },
  });
  const { handleSubmit, control, setValue, formState } = form;

  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });
  const startTime = useWatch({ control, name: 'startTime' });
  const endTime = useWatch({ control, name: 'endTime' });

  /**
   * The startDate = today => validate minTime = startTime
   * The startDate !== today => don't set minTime
   */
  const getMinTimeStartTime = useMemo(() => {
    const originalHours = new Date().getHours() % 24;
    //
    if (checkEqualDate(startDate?.format('MM/DD/YYYY'))) {
      return new Date(0, 0, 0, originalHours, new Date().getMinutes());
    }
    return new Date(0, 0, 0, 0, 0);
    // eslint-disable-next-line
  }, [startDate]);

  // const getMinTimeEndTime = useMemo(() => {
  //   const originalHours = startTime?.getHours();
  //   const originalMins = startTime?.getMinutes();

  //   const newHours = (originalHours + 2) % 24;
  //   return new Date(0, 0, 0, newHours, originalMins);
  //   // eslint-disable-next-line
  // }, [startTime, jobDetail]);

  const getMaxTime = useMemo(() => {
    const resultCompare = compareDateOnly(startDate, endDate);
    if (resultCompare === COMPARE_DATE.IS_SAME) {
      // Set the maximum time of the day (23:59)
      return new Date(0, 0, 0, 23, 59);
    } else {
      const maxTime = new Date(startTime);
      // Set the date to the next day
      maxTime.setDate(startTime.getDate() + 1);
      // Set the time to the end of next day
      maxTime.setHours(23, 59, 59, 999);
      return maxTime;
    }
    // eslint-disable-next-line
  }, [jobDetail, startTime, endDate]);

  const onSubmit = async (values: EDIT_JOB_FORM) => {
    dispatch(setGlobalLoading(true));
    dispatch(
      dispatch(
        updateJobAction({
          data: {
            id: id,
            end_datetime: convertToUtcTime(
              mergeDateTime(values.endDate, values.endTime),
            ),
          },
          onSuccess: () => {
            navigate(-1);
            NotifyService.success(SYS_MESS.SUCCESS.UPDATE_JOB);
          },
        }),
      ),
    );
  };

  useEffect(() => {
    if (!jobDetail) {
      dispatch(getJobDetailAction({ data: { id } }));
      return;
    }
    setValue('startDate', moment(jobDetail.start_datetime));
    setValue('endDate', moment(jobDetail.end_datetime));
    setValue('startTime', convertToLocalTime(jobDetail.start_datetime));
    setValue('endTime', convertToLocalTime(jobDetail.end_datetime));

    return () => {
      if (jobDetail) {
        dispatch(updateJobDetail(null));
      }
    };
    // eslint-disable-next-line
  }, [jobDetail]);

  useEffect(() => {
    if (!startDate) return;
    setValue('startTime', mergeDateTime(startDate, startTime));
    // eslint-disable-next-line
  }, [startDate]);

  useEffect(() => {
    if (!endDate) return;
    form.trigger('endTime');
    setValue('endTime', mergeDateTime(endDate, endTime));
    // eslint-disable-next-line
  }, [endDate]);

  useEffect(() => {
    setTimeout(() => setIsFirstRender(false), 1000);
    if (isFirstRender) return;
    const isSameDay =
      startDate?.format('YYYY-MM-DD') === endDate?.format('YYYY-MM-DD');

    if (isSameDay && endTime?.getHours() <= startTime?.getHours()) {
      // If same day and end time is before start time, add a day
      setValue('endDate', moment(endDate).add(1, 'day'));
    }
    // eslint-disable-next-line
  }, [endTime]);

  if (!jobDetail) return;
  return (
    <EditBookingContainer spacing={4}>
      <Stack>
        <Typography fontSize={22} fontWeight={700} color="black.dark">
          Edit Booking
        </Typography>
        <Typography fontSize={18} fontWeight={400} color="text.secondary">
          Extend or shorten duration
        </Typography>
      </Stack>
      {[
        JOB_STATUS.CANCELLED,
        JOB_STATUS.STARTED,
        JOB_STATUS.PENDING_COMPLETED,
        JOB_STATUS.COMPLETED,
      ].includes(jobDetail?.status) && (
        <Typography variant="errors">
          This job has been {mapBookingTitle[jobDetail.status]}
        </Typography>
      )}
      <FormEditBookingContainer onSubmit={handleSubmit(onSubmit)}>
        <Stack flexDirection={'row'} gap={2}>
          <DatePickerField
            control={control}
            name={'startDate'}
            iconColor="primary"
            placeholder="Expected Start Date"
            label="Start Date"
            minDate={moment()}
            disabled
          />
          <TimePickerField
            control={control}
            name={'startTime'}
            placeholderText="Expected Start Time"
            label="Start Time"
            minTime={getMinTimeStartTime}
            maxTime={getMaxTime}
            disabled
          />
        </Stack>
        <Stack flexDirection={'row'} gap={2}>
          <DatePickerField
            control={control}
            name={'endDate'}
            iconColor="primary"
            placeholder="Expected End Date"
            label="End Date"
            minDate={moment(jobDetail?.end_datetime)}
          />
          <TimePickerField
            control={control}
            name={'endTime'}
            placeholderText="End Time"
            label="End Time"
            // minTime={getMinTimeEndTime}
            // maxTime={getMaxTime}
            errorMess={formState.errors.endTime?.message}
          />
        </Stack>
        <Button
          variant="contained"
          type="submit"
          disabled={
            [
              JOB_STATUS.CANCELLED,
              JOB_STATUS.STARTED,
              JOB_STATUS.PENDING_COMPLETED,
              JOB_STATUS.COMPLETED,
            ].includes(jobDetail?.status) || !formState.isValid
          }
        >
          Save
        </Button>
        <Button variant="text" onClick={() => navigate(-1)}>
          Back
        </Button>
      </FormEditBookingContainer>
    </EditBookingContainer>
  );
}

export default EditJob;
