import { METADATA, PARAMETERS } from './common';

/* =========== ENUM ============ */
export enum PURCHASED_PACKAGE_FROM {
  JOB = 'payForJob',
  CREDIT = 'payForCredit',
}

/* =========== INTERFACE ============ */
export interface PARENT_SUBSCRIPTION_DATA {
  profile_picture: string | null;
  User: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
  };
}
export interface CURRENT_BALANCE_CREDIT_RESPONSE {
  balance: number;
  sitter_rate: number;
  id: string;
  parent_id: string;
  number: string;
  is_trial: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  parent: PARENT_SUBSCRIPTION_DATA;
}

export interface TRANSACTION_CREDIT_ITEM_TYPE {}

export interface AVAILABLE_PACKAGE_RESPONSE {
  metadata: METADATA;
  data: AVAILABLE_PACKAGE_ITEM_TYPE[];
}

export interface AVAILABLE_PACKAGE_ITEM_TYPE {
  id: string;
  name: string;
  description: string;
  parent_rate: number;
  price: number;
  is_active: boolean;
  is_read_only: boolean;
  tag: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export interface GET_SUBSCRIPTION_PURCHASED_PARAMS extends PARAMETERS {
  has_balance: boolean;
  parent_id: string;
}

export interface SUBSCRIPTION_PURCHASED_RESPONSE {
  metadata: METADATA;
  records: SUBSCRIPTION_PURCHASED_ITEM[];
}

export interface SUBSCRIPTION_PURCHASED_ITEM
  extends CURRENT_BALANCE_CREDIT_RESPONSE {
  package: {
    parent_rate: number;
    sitter_rate: number;
    price: number;
    id: string;
    name: string;
  };
}
