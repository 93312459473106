import { Button, Stack, Typography } from '@mui/material';
import { CheckCircleIcon, CloseCircle, HelpCircleIcon } from 'assets';
import ModalUISmall from 'components/common/ModalUISmall';
import { ReactNode, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledButtonWrapper } from './styles';

export enum TYPE_ICON {
  CANCEL = 'cancel',
  HELP = 'help',
  CLOSE = 'close',
  CHECK = 'check',
} // 'cancel' | 'help' | 'close' | 'check';

interface PopupConfirmProps {
  typeIcon?: TYPE_ICON;
  handleClose: () => void;
  title: string;
  content: string | ReactNode;
  hrefButton?: any;
  onSubmit?: () => void;
  textButton?: string;
  textButton2?: string;
  handleBack?: () => void;
  justCloseWithBtn?: boolean;
}

export const PopupConfirm = memo(
  ({
    typeIcon = null,
    title,
    content,
    handleClose,
    hrefButton,
    onSubmit,
    textButton = 'Confirm',
    textButton2,
    handleBack,
    justCloseWithBtn,
  }: PopupConfirmProps) => {
    const navigate = useNavigate();

    const handleClickConfirm = () => {
      handleClose();
      if (hrefButton) return navigate(hrefButton);
      onSubmit?.();
    };

    const getPopupIcon = () => {
      const styleIcon = { width: '55px', height: '55px' };
      switch (typeIcon) {
        case TYPE_ICON.CHECK:
          return <CheckCircleIcon color="primary" sx={styleIcon} />;
        case TYPE_ICON.HELP:
          return <HelpCircleIcon color="primary" sx={styleIcon} />;
        case TYPE_ICON.CLOSE:
          return <CloseCircle color="primary" sx={styleIcon} />;
        case TYPE_ICON.CANCEL:
          return <CloseCircle color="primary" sx={styleIcon} />;
        default:
          return <></>;
      }
    };

    return (
      <ModalUISmall
        open
        hideCloseButton
        onClose={!justCloseWithBtn && handleClose}
      >
        <Stack spacing={2} justifyContent="center" alignItems="center">
          {getPopupIcon()}
          <Typography variant="Web_Title_18" color="primary.main">
            {title}
          </Typography>
          <Typography textAlign={'center'}>{content}</Typography>
          <StyledButtonWrapper $twoButton={textButton2 !== undefined}>
            {textButton2 && (
              <Button
                onClick={() => {
                  handleBack?.();
                  handleClose();
                }}
                variant="contained"
                className="btn-2"
              >
                {textButton2}
              </Button>
            )}
            <Button onClick={handleClickConfirm} variant="contained">
              {textButton}
            </Button>
          </StyledButtonWrapper>
        </Stack>
      </ModalUISmall>
    );
  },
);

export default PopupConfirm;
