import { CircularProgress, Grid } from '@mui/material';
import { METADATA } from 'models';
import { CSSProperties, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface ScrollLoadMoreProps<T> {
  data: T;
  metadata: METADATA;
  loadMore: () => void;
  children: ReactNode;
  scrollableTarget?: string;
  style?: CSSProperties;
  loader?: ReactNode;
  scrollThreshold?: string | number;
}

const ScrollLoadMore = ({
  data,
  metadata,
  loadMore,
  children,
  scrollableTarget,
  style,
  loader,
  scrollThreshold,
}: ScrollLoadMoreProps<any>) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={loadMore}
      hasMore={metadata.page < metadata.total_pages}
      scrollableTarget={scrollableTarget}
      scrollThreshold={scrollThreshold}
      loader={
        loader ?? (
          <Grid container my={4} justifyContent="center">
            <CircularProgress
              sx={{
                color: 'primary.main',
              }}
              size={30}
            />
          </Grid>
        )
      }
      style={style}
    >
      {children}
    </InfiniteScroll>
  );
};

export default ScrollLoadMore;
