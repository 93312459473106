import { Stack, Typography } from '@mui/material';
import { PaymentComponent } from 'components';
import PopupConfirm, { TYPE_ICON } from 'components/popup/PopupConfirm';
import { SYS_MESS } from 'constants/systemMessage';
import { useQueryString } from 'hooks';
import { PURCHASED_PACKAGE_FROM } from 'models/credits';
import { PAYMENT_MODAL_TYPE, PAYMENT_TYPE } from 'models/payments';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setOpenPaymentSuccess } from 'store/reducers/payments';
import {
  checkoutPurchasePackageAction,
  payForBuyPackageAction,
} from 'store/reducers/payments/actionTypes';
import { formatPrice, pluralString } from 'utils';

const PaymentCredits = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const query = useQueryString();
  const { openPaymentSuccess, paymentMethodId } = useAppSelector(
    (state) => state.payment,
  );
  const { selectedPackageId, availablePackages } = useAppSelector(
    (state) => state.credits,
  );

  const selectedPackage = availablePackages?.records?.find((item) =>
    item.id.includes(selectedPackageId),
  );

  const prevUrlJob = query.type === PURCHASED_PACKAGE_FROM.JOB;

  const handleBackAction = () => {
    if (prevUrlJob) {
      navigate(-1);
    } else navigate(APP_ROUTES.CREDITS.OVERVIEW.to);
  };

  const getEnhancedPlan = () => {
    if (selectedPackage) {
      return +(selectedPackage.price / selectedPackage.parent_rate).toFixed();
    }
  };

  const paymentCallback = (isPayNow: boolean, callback?: Function) => {
    const _params = {
      payment_method_id: !isPayNow ? paymentMethodId : undefined,
      payment_method_type: isPayNow
        ? PAYMENT_MODAL_TYPE.PAY_NOW
        : PAYMENT_MODAL_TYPE.CARD,
      package_id: selectedPackageId,
    };

    dispatch(
      payForBuyPackageAction({
        data: _params,
        onSuccess: (qrCode: string) => {
          if (isPayNow) {
            callback?.(qrCode);
            return;
          }
          dispatch(setOpenPaymentSuccess(true));
        },
        onFailed: () => {},
        onFinally: () => {},
      }),
    );
  };

  useEffect(() => {
    if (!selectedPackage) {
      navigate(APP_ROUTES.CREDITS.BUY_PACKAGE.to);
    }
    // eslint-disable-next-line
  }, []);

  const checkoutAction = (isPayNow: boolean) => {
    dispatch(
      checkoutPurchasePackageAction({
        data: {
          package_id: selectedPackage.id,
          payment_method_type: isPayNow
            ? PAYMENT_MODAL_TYPE.PAY_NOW
            : PAYMENT_MODAL_TYPE.CARD,
        },
      }),
    );
  };

  return (
    <Stack spacing={8}>
      {openPaymentSuccess && (
        <PopupConfirm
          typeIcon={TYPE_ICON.CHECK}
          title={SYS_MESS.SUCCESS.PAYMENT}
          content={
            <Typography variant="Web_Label_14">
              Your payment has been successfully made
            </Typography>
          }
          textButton={`Back to ${prevUrlJob ? 'Bookings' : 'Credits'}`}
          hrefButton={prevUrlJob ? -1 : APP_ROUTES.CREDITS.OVERVIEW.to}
          justCloseWithBtn
          handleClose={() => {
            dispatch(setOpenPaymentSuccess(false));
          }}
        />
      )}
      <Stack spacing={1} alignItems="center">
        <Typography variant="Web_Title_22">Payment</Typography>
        <Typography variant="Web_Label_18">Buy Sitter Plan</Typography>
      </Stack>
      <React.Fragment>
        <PaymentComponent
          moreFeeChildren={
            <Stack>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                Enhanced Plan - {pluralString('hour', getEnhancedPlan())}
              </Typography>
              <Typography variant="Components_Column_content" color={'#6d6d6d'}>
                {formatPrice(selectedPackage?.price)}
              </Typography>
            </Stack>
          }
          paymentType={PAYMENT_TYPE.PURCHASE_PACKAGE}
          checkoutAction={checkoutAction}
          paymentCallback={paymentCallback}
          handleBackAction={handleBackAction}
        />
      </React.Fragment>
    </Stack>
  );
};

export default PaymentCredits;
