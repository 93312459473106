import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import { PayloadActionData, RESPONSE_TYPE } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { paymentApi } from 'services/payment';
import { setGlobalLoading } from 'store/reducers/global';
import {
  updateDataCheckoutPayment,
  updatePaymentIntentId,
} from 'store/reducers/payments';
import {
  CHECKOUT_AC_JOB_REQUEST,
  CHECKOUT_EXTEND_JOB_REQUEST,
  CHECKOUT_TRIAL_JOB_REQUEST,
  PAY_EXTEND_JOB_BY_TOP_UP_REQUEST,
  PAY_FOR_EXTEND_JOB_REQUEST,
  PAY_FOR_EXTEND_TRIAL_JOB_REQUEST,
  PAY_FOR_NORMAL_JOB_REQUEST,
  PAY_FOR_TRIAL_JOB_REQUEST,
  PAY_JOB_BY_TOP_UP_REQUEST,
} from 'store/reducers/payments/actionTypes';
import {
  CHECKOUT_PAYMENT_RESPONSE,
  PAYMENT_RESPONSE,
  PAY_JOB_PAYLOAD,
} from '../../../models/payments';

function* payForTrialJobRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.trialJob,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;
    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payForExtendTrialJobRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.extendTrialJob,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;
    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payForNormalJobRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.normalJob,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;

    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payForExtendJobRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.extendJob,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;
    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payJobWithLackOfCreditRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.payJobByTopUp,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;
    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* checkoutForTrialJobRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>> =
      yield call(paymentApi.checkoutTrialJob, action.payload.data);
    action.payload.onSuccess?.(response.data.data);
    yield put(updateDataCheckoutPayment(response.data.data));
    console.log(response);
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* checkoutACJobRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>> =
      yield call(paymentApi.checkoutACJob, action.payload.data);
    action.payload.onSuccess?.(response.data.data);
    yield put(updateDataCheckoutPayment(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* checkoutExtendJobRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>> =
      yield call(paymentApi.checkoutExtendACJob, action.payload.data);
    action.payload.onSuccess?.(response.data.data);
    yield put(updateDataCheckoutPayment(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payExtendJobByTopUpRequest(
  action: PayloadAction<PayloadActionData<PAY_JOB_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.payExtendJobByTopUp,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;
    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* paymentForJobSaga() {
  yield takeLatest(PAY_FOR_TRIAL_JOB_REQUEST, payForTrialJobRequest);
  yield takeLatest(
    PAY_FOR_EXTEND_TRIAL_JOB_REQUEST,
    payForExtendTrialJobRequest,
  );
  yield takeLatest(PAY_FOR_NORMAL_JOB_REQUEST, payForNormalJobRequest);
  yield takeLatest(PAY_FOR_EXTEND_JOB_REQUEST, payForExtendJobRequest);
  yield takeLatest(PAY_JOB_BY_TOP_UP_REQUEST, payJobWithLackOfCreditRequest);
  yield takeLatest(CHECKOUT_TRIAL_JOB_REQUEST, checkoutForTrialJobRequest);
  yield takeLatest(CHECKOUT_AC_JOB_REQUEST, checkoutACJobRequest);
  yield takeLatest(CHECKOUT_EXTEND_JOB_REQUEST, checkoutExtendJobRequest);
  yield takeLatest(
    PAY_EXTEND_JOB_BY_TOP_UP_REQUEST,
    payExtendJobByTopUpRequest,
  );
}
export default paymentForJobSaga;
