import { memo } from 'react';

/* =========== ENUM ============ */
export enum EORDER_SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum USER_TYPE {
  PARENT = 'PARENT',
  NANNY = 'NANNY',
}

export enum ACTION_TYPE {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  SUBMITTED = 'submitted',
  CANCEL = 'cancel',
  CANCELLED = 'canceled',
  EXTEND = 'extend',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  PENDING = 'pending',
}

export enum CONNECTION_WSS_STATUS {
  CONNECTED = 'connect',
  CONNECT_ERROR = 'connect_error',
  DISCONNECT = 'disconnect',
  CLOSE = 'close',
}

export enum EVENT_WSS {
  SEND_MESSAGE = 'send message',
  PAYMENT_SEARCH_FEE_SUCCESS = 'search fee paid successfully',
  PAYMENT_TRIAL_JOB_SUCCESS = 'trial job paid successfully',
  PACKAGE_PURCHASE_SUCCESS = 'package purchased successfully',
  PAYMENT_JOB_BY_TOP_UP_SUCCESS = 'ac job paid successfully',
}

/* =========== INTERFACE ============ */
export type Opt<T> = T | null | undefined;
export type Dict<T> = Record<string, T>;

//models response & error from api
export interface RESPONSE_TYPE<TData = any> {
  status: number;
  message: string;
  data: TData;
}

export interface PAGINATION_TYPE<TData = any> {
  metadata: METADATA;
  rows: TData;
}

export interface PAGINATION2_TYPE<TData = any> {
  metadata: METADATA;
  records: TData;
}

export interface METADATA {
  // new metadata
  page: number;
  limit: number;
  page_count: number;
  total_pages: number;
  total_count: number;
  // old metadata
  pageIndex?: number;
  pageSize?: number;
  totalCount?: number;
  totalPages?: number;
}

export interface PARAMETERS {
  page?: number;
  limit?: number;
}

export interface REDUX_ACTION_CALLBACK<T = any> {
  onSuccess?: (data?: T) => void;
  onError?: (e: any) => void;
}

export interface REDUX_ACTION_PAYLOAD<T1 = any, T2 = any> {
  data: T1;
  onSuccess?: (data?: T2) => void;
  onError?: (e: any) => void;
}

export interface OPTION_ITEM<T extends any = number> {
  id?: T;
  name: string;
  [key: string]: any;
}

export interface PayloadActionData<T1 extends any = any, T2 extends any = any> {
  data?: T1;
  onSuccess?: (data?: T2) => void;
  onFailed?: (err?: any) => void;
  onFinally?: () => void;
}

export interface PHONE_NUMBER {
  countryCode: string;
  phoneNumber: string;
}

export const typedMemo: <T>(
  c: T,
  areEqual?: (
    prevProps: Readonly<React.PropsWithChildren<T>>,
    nextProps: Readonly<React.PropsWithChildren<T>>,
  ) => boolean,
) => T = memo;

export interface MODAL_ACTION<T extends any = any> {
  data?: T;
  type: ACTION_TYPE;
  custom?: any;
}
