import { Stack, Typography } from '@mui/material';
import SitterPanel from 'components/common/SitterPanel';
import { SITTER_TYPE } from 'models/sitter';

interface Props {
  sitter: SITTER_TYPE;
}

export const SectionOthers = ({ sitter }: Props) => {
  const languageProficiency = sitter?.checkLists.find(
    (item) => item.section_name === 'What languages do you speak?',
  );
  const petComfortable = sitter?.checkLists.find(
    (item) => item.section_name === 'Are you comfortable with pets?',
  );
  const preferredLocation = sitter?.checkLists.find(
    (item) => item.section_name === 'Preferred Sit Location',
  );
  const overnightSits = sitter?.checkLists.find(
    (item) => item.section_name === 'Overnight Sits',
  );

  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">Others</Typography>
      <Stack spacing={{ xs: 1, md: 3 }} direction={{ xs: 'column', md: 'row' }}>
        <SitterPanel
          item={{
            section_title: 'Language Proficiency',
            items: languageProficiency?.items ?? [],
          }}
        />
        <SitterPanel
          item={{
            section_title: 'Comfortable with Pets',
            items: petComfortable?.items ?? [],
          }}
        />
      </Stack>
      <Stack spacing={{ xs: 1, md: 3 }} direction={{ xs: 'column', md: 'row' }}>
        <SitterPanel
          item={{
            section_title: 'Preferred Sit Locations',
            items: preferredLocation?.items ?? [],
          }}
        />
        <SitterPanel
          item={{
            section_title: 'Overnight Sit',
            items: overnightSits?.items ?? [],
          }}
        />
      </Stack>
    </Stack>
  );
};
