import { GET_LIST_JOB_PARAMS, PayloadActionData } from 'models';
import { UPDATE_JOB_PAYLOAD } from 'models/job';

export const GET_JOB_LIST_REQUEST = 'GET_JOB_LIST_REQUEST';
export const GET_JOB_DETAIL_REQUEST = 'GET_JOB_DETAIL_REQUEST';
export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const CANCEL_JOB_REQUEST = 'CANCEL_JOB_REQUEST';
export const GET_PREVIEW_EXTEND_JOB_REQUEST = 'GET_PREVIEW_EXTEND_JOB_REQUEST';

export const getJobListAction = (
  payload: PayloadActionData<GET_LIST_JOB_PARAMS>,
) => ({
  type: GET_JOB_LIST_REQUEST,
  payload,
});

export const getJobDetailAction = (
  payload: PayloadActionData<{ id: string }>,
) => ({
  type: GET_JOB_DETAIL_REQUEST,
  payload,
});

export const updateJobAction = (
  payload: PayloadActionData<UPDATE_JOB_PAYLOAD>,
) => ({
  type: UPDATE_JOB_REQUEST,
  payload,
});

export const getPreviewExtendJobAction = (
  payload: PayloadActionData<{ id: string }>,
) => ({
  type: GET_PREVIEW_EXTEND_JOB_REQUEST,
  payload,
});

export const cancelJobAction = (
  payload: PayloadActionData<{ id: string }>,
) => ({
  type: CANCEL_JOB_REQUEST,
  payload,
});
