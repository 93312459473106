import { Button, Stack, Typography } from '@mui/material';
import { LogoIcon } from 'assets';
import { useAppDispatch } from 'store/hook';
import { setOpenBuyCredits } from 'store/reducers/payments';
import { formatPrice } from 'utils';
import { StyledCreditBalance } from '../../styles';

interface CreditBalanceProps {
  creditBalance: number;
  isInsufficientBalance: boolean;
  canPurchasePackage: boolean;
}

function CreditBalanceView({
  creditBalance,
  isInsufficientBalance,
  canPurchasePackage,
}: CreditBalanceProps) {
  const dispatch = useAppDispatch();

  return (
    <Stack spacing={1}>
      <StyledCreditBalance bgcolor={'light.shades4'}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <LogoIcon sx={{ color: 'primary.main' }} />
          <Typography variant="Small_Body_16">Credit Balance</Typography>
        </Stack>
        <Typography variant="Small_Profile_16">
          {formatPrice(creditBalance)}
        </Typography>
      </StyledCreditBalance>
      {isInsufficientBalance && canPurchasePackage && (
        <StyledCreditBalance bgcolor={'light.shades8'}>
          <Typography variant="Components_Column_content" color="primary">
            You have insufficient credits to accept this booking
          </Typography>
          <Button
            variant="contained"
            onClick={() => dispatch(setOpenBuyCredits(true))}
          >
            Buy Credit
          </Button>
        </StyledCreditBalance>
      )}
    </Stack>
  );
}

export default CreditBalanceView;
