import { Avatar, Divider, Grid, Stack, Typography } from '@mui/material';
import { DATE_TIME_FORMAT, DEFAULT_FORMAT_DATE } from 'constants/date';
import {
  ageRangeOptions,
  daysOfWeekOptions,
} from 'features/create-request/helpers';
import { StyledRequestOverview } from 'features/overview/styled';
import { formatDate } from 'helpers';
import {
  mapBaseRequestColor,
  mapBaseRequestTitle,
} from 'models/create-request';
import { OVERVIEW_CHILDREN_ITEM, OVERVIEW_RESPONSE } from 'models/overview';
import { theme } from 'theme';
import { pluralString } from 'utils';

export const RequestOverview = ({
  requestOverviewData,
}: {
  requestOverviewData: OVERVIEW_RESPONSE;
}) => {
  const pets = Object.keys(requestOverviewData.pets).filter(
    (key) =>
      requestOverviewData.pets[key as keyof typeof requestOverviewData.pets] ===
      true,
  );

  const dayOfWeekSelected = requestOverviewData.days_of_week
    .map((apiDay) => daysOfWeekOptions.find((option) => option.id === apiDay))
    .filter(Boolean);

  const getAddress = () => {
    return `
    ${requestOverviewData.address.street}, 
    #${requestOverviewData.address.unit_number}-${requestOverviewData.address.block_number}
    ${requestOverviewData.address.country}
    ${requestOverviewData.address.postal_code}`;
  };

  return (
    <StyledRequestOverview spacing={2}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'start', md: 'space-between' }}
        spacing={{ xs: 4, md: 0 }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 0.5 }}
        >
          <Typography variant="Web_Label_18">Expected Due Date:</Typography>
          <Typography variant="Web_Title_18" color="primary">
            {formatDate(requestOverviewData.start_datetime, {
              format: DEFAULT_FORMAT_DATE,
            })}
          </Typography>
        </Stack>
        <Typography
          variant="Web_Label_18"
          sx={{
            textTransform: 'capitalize',
          }}
          color={
            mapBaseRequestColor[
              requestOverviewData.status as keyof typeof mapBaseRequestColor
            ]
          }
        >
          {
            mapBaseRequestTitle[
              requestOverviewData.status as keyof typeof mapBaseRequestTitle
            ]
          }
        </Typography>
      </Stack>
      <Divider />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 4, md: 14 }}
      >
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
            End Date
          </Typography>
          <Typography variant="Small_Body_16">
            {formatDate(requestOverviewData.end_datetime, {
              format: DEFAULT_FORMAT_DATE,
            })}
          </Typography>
        </Stack>
        <Stack spacing={{ xs: 0.5 }}>
          <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
            Estimated No. of Hours per week
          </Typography>
          <Typography variant="Small_Body_16">
            {pluralString('hour', 50)}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        className="child_panel"
        direction={{ xs: 'column', md: 'row' }}
        spacing={4}
      >
        {requestOverviewData.children.map(
          (children: OVERVIEW_CHILDREN_ITEM) => (
            <Stack
              direction="row"
              key={children.id}
              spacing={1}
              alignItems="center"
            >
              <Avatar sx={{ width: 44, height: 44, bgcolor: 'primary.main' }}>
                {children.full_name.charAt(0)}
              </Avatar>
              <Stack spacing={0.25}>
                <Typography variant="Title_Menu_14">
                  {children.full_name}
                </Typography>
                <Typography variant="Small_Body_12">
                  {
                    ageRangeOptions.find(
                      (item) => item.id === children.age_range,
                    )?.name
                  }
                </Typography>
              </Stack>
            </Stack>
          ),
        )}
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Address
        </Typography>
        <Typography variant="Small_Body_12">{getAddress()}</Typography>
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Dates & Timings
        </Typography>
        <Grid>
          {dayOfWeekSelected.map((day, index) => (
            <Typography variant="Small_Body_12" key={day.id}>
              {day.fullName}
              {index + 1 < dayOfWeekSelected.length && ', '}
            </Typography>
          ))}
        </Grid>
        <Typography variant="Small_Body_12">
          {formatDate(requestOverviewData.start_datetime, {
            format: DATE_TIME_FORMAT.HOUR_ONLY,
          })}
          &nbsp;to&nbsp;
          {formatDate(requestOverviewData.end_datetime, {
            format: DATE_TIME_FORMAT.HOUR_ONLY,
          })}
        </Typography>
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Other Requirements
        </Typography>
        <Typography variant="Small_Body_12">
          {requestOverviewData.special_requirements}
        </Typography>
      </Stack>
      <Stack spacing={{ xs: 0.5 }}>
        <Typography variant="Web_Label_16" color={theme.palette.grey3.dark}>
          Pets
        </Typography>
        <Grid>
          {pets.map((pet, index) => {
            return (
              <Typography variant="Small_Body_12" key={index}>
                {pet.charAt(0).toUpperCase() + pet.slice(1)}
                {index + 1 < pets.length && ', '}
              </Typography>
            );
          })}
        </Grid>
      </Stack>
      <Divider />
      <Stack color={theme.palette.grey3.dark} direction="row" spacing={0.5}>
        <Typography variant="Small_Body_16">Created on:</Typography>
        <Typography variant="Small_Body_16">
          {formatDate(requestOverviewData.createdAt, {
            format: DEFAULT_FORMAT_DATE,
          })}
        </Typography>
      </Stack>
    </StyledRequestOverview>
  );
};
