import PageError from 'components/common/PageError';
import ParentFeature from 'features';
import { Navigate, RouteObject } from 'react-router-dom';
import { RedirectAuth } from './RedirectAuth';
import { APP_ROUTES } from './routes';

const configAppRoutes: RouteObject[] = [
  {
    path: APP_ROUTES.path,
    element: <ParentFeature />,
  },
  {
    path: APP_ROUTES.NOT_FOUND.path,
    element: (
      <RedirectAuth redirectTo={APP_ROUTES.AUTH.LOGIN.to}>
        <PageError />
      </RedirectAuth>
    ),
  },
  {
    path: '*',
    element: <Navigate to={APP_ROUTES.NOT_FOUND.to} replace />,
  },
];

export default configAppRoutes;
