import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import { PayloadActionData, RESPONSE_TYPE } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { paymentApi } from 'services/payment';
import { setGlobalLoading } from 'store/reducers/global';
import {
  updateDataCheckoutPayment,
  updatePaymentIntentId,
} from 'store/reducers/payments';
import {
  CHECKOUT_PURCHASE_PACKAGE_REQUEST,
  PAY_FOR_BUY_PACKAGE_REQUEST,
} from 'store/reducers/payments/actionTypes';
import {
  CHECKOUT_PAYMENT_RESPONSE,
  PAYMENT_RESPONSE,
  PURCHASE_PACKAGE_PAYLOAD,
} from '../../../models/payments';

function* payForBuyPackageRequest(
  action: PayloadAction<PayloadActionData<PURCHASE_PACKAGE_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.purchasePackage,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;
    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* checkoutPurchasePackageRequest(
  action: PayloadAction<PayloadActionData<PURCHASE_PACKAGE_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>> =
      yield call(paymentApi.checkoutPurchasePackage, action.payload.data);
    action.payload.onSuccess?.(response.data.data);
    yield put(updateDataCheckoutPayment(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* payPackageSaga() {
  yield takeLatest(PAY_FOR_BUY_PACKAGE_REQUEST, payForBuyPackageRequest);
  yield takeLatest(
    CHECKOUT_PURCHASE_PACKAGE_REQUEST,
    checkoutPurchasePackageRequest,
  );
}
export default payPackageSaga;
