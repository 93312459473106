import { Stack, Typography } from '@mui/material';
import ModalUI from 'components/common/ModalUI';
import PackageCreditItem from 'components/common/PackageCreditItem';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getPackageAvailableAction } from 'store/reducers/credits/actionTypes';

interface PopupBuyCreditsProps {
  _onClosePopup: () => void;
}

export function PopupBuyCredits({ _onClosePopup }: PopupBuyCreditsProps) {
  const dispatch = useAppDispatch();
  const { openBuyCredits } = useAppSelector((state) => state.payment);
  const { availablePackages } = useAppSelector((state) => state.credits);

  useEffect(() => {
    dispatch(getPackageAvailableAction());
  }, [dispatch]);

  if (!availablePackages) return;
  return (
    <ModalUI open={openBuyCredits} maxW={900} onClose={_onClosePopup}>
      <Stack spacing={1} alignItems="center" pb={4}>
        <Typography variant="Web_Title_22">Buy Credits</Typography>
        <Typography variant="Web_Label_18">
          Select a Sitter Plan to purchase
        </Typography>
      </Stack>
      <Stack width={'90%'} margin={'auto'}>
        <Stack
          flexDirection={'row'}
          alignItems="center"
          justifyContent="center"
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          sx={{ gap: 1 }}
        >
          {availablePackages.records.length > 0 ? (
            availablePackages.records?.map((item) => (
              <PackageCreditItem key={item.id} data={item} isPayWithCredit />
            ))
          ) : (
            <Typography variant="Web_Label_14">
              There are no packages available
            </Typography>
          )}
        </Stack>
      </Stack>
    </ModalUI>
  );
}

export default PopupBuyCredits;
