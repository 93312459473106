import { all } from 'redux-saga/effects';
import { authSagas } from './auth';
import { creditSaga } from './credits';
import { jobSagas } from './jobs';
import { overviewSaga } from './overview';
import { parentSaga } from './parent';
import { paymentSagas } from './payments';

export const rootSaga = function* root() {
  yield all([
    authSagas(),
    creditSaga(),
    parentSaga(),
    paymentSagas(),
    jobSagas(),
    overviewSaga(),
  ]);
};
