import styled from '@emotion/styled';
import { InputBase, inputBaseClasses } from '@mui/material';
import { theme } from 'theme';
import { transientOptions } from 'utils/transientOptions';

export const StyledInput = styled(InputBase, transientOptions)<{
  $disabled: boolean;
}>`
  width: 100%;
  height: 48px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${theme.palette.light.stroke};
  background: ${({ $disabled }) =>
    !$disabled
      ? theme.palette.background.light
      : theme.palette.background.dark};

  & .${inputBaseClasses.input} {
    padding-left: 15px;
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: ${theme.palette.black.dark} !important;
    ::placeholder {
      color: ${theme.palette.light.quaternary};
    }
    -webkit-text-fill-color: unset;
  }
  & svg {
    margin-right: 15px;
    color: ${theme.palette.black.dark};
  }
`;
