import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  InputField,
  StripeCardCVCField,
  StripeCardExpiryField,
  StripeCardNumberField,
} from 'components';
import { NotifyService } from 'config';
import yup from 'config/yup.custom';
import { SYS_MESS } from 'constants/systemMessage';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useAppDispatch } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import {
  setOpenPaymentModal,
  updatePaymentMethodId,
} from 'store/reducers/payments';
import {
  attachPaymentMethodAction,
  getListCardsAction,
} from 'store/reducers/payments/actionTypes';
import { StyledStripeForm } from './styles';

interface IStripeForm {
  cardHolderName?: string;
}

interface IStripeFieldState {
  cardNumberError: string;
  cardNumberComplete: boolean;
  cardExpiredError: string;
  cardExpiredComplete: boolean;
  cardCVCError: string;
  cardCVCComplete: boolean;
  cardHolderNameError: string;
  cardHolderNameComplete: boolean;
}

const schema = yup.object({}).required();

export const StripeFormProvider = () => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { handleSubmit, control } = useForm<IStripeForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const valueCardName = useWatch({
    control,
    name: 'cardHolderName',
  });

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [stateCards, setStateCards] = useState<IStripeFieldState>({
    cardNumberError: '',
    cardNumberComplete: false,
    cardExpiredError: '',
    cardExpiredComplete: false,
    cardCVCError: '',
    cardCVCComplete: false,
    cardHolderNameError: '',
    cardHolderNameComplete: false,
  });

  const onElementChange =
    (fieldName: any, errField: any) =>
    ({ complete, error = { message: '' } }: any) => {
      setStateCards((prev) => ({
        ...prev,
        [fieldName]: complete,
        [errField]: error.message,
      }));
    };

  const {
    cardNumberError,
    cardExpiredError,
    cardCVCError,
    cardHolderNameError,
    cardHolderNameComplete,
    cardNumberComplete,
    cardCVCComplete,
    cardExpiredComplete,
  } = stateCards;

  const isCompleteAll =
    cardNumberComplete &&
    cardExpiredComplete &&
    cardCVCComplete &&
    cardHolderNameComplete;

  const validateForm = () => {
    if (!isCompleteAll && !isSubmitted) {
      return;
    }
    setStateCards((prev) => ({
      ...prev,
      cardNumberError:
        !cardNumberComplete && SYS_MESS.ERROR.PAYMENTS.NUMBER_CARD,
      cardExpiredError:
        !cardExpiredComplete && SYS_MESS.ERROR.PAYMENTS.EXPIRED_DATE_CARD,
      cardCVCError: !cardCVCComplete && SYS_MESS.ERROR.PAYMENTS.CVC_CARD,
      cardHolderNameError:
        !valueCardName && SYS_MESS.ERROR.PAYMENTS.HOLDER_NAME_CARD,
    }));

    return (
      cardNumberComplete &&
      cardExpiredComplete &&
      cardCVCComplete &&
      cardHolderNameComplete
    );
  };

  useEffect(() => {
    setStateCards((prev) => ({
      ...prev,
      cardHolderNameComplete: !!valueCardName,
    }));
  }, [valueCardName]);

  const getFieldError = (isComplete: boolean, error: string, field: string) => {
    if (isSubmitted) {
      if (error) return error;
      if (!isComplete) return `Your ${field} is incomplete.`;
      return '';
    }
  };

  const onSubmit = async (values: IStripeForm) => {
    setIsSubmitted(true);
    if (!stripe || !elements || !validateForm()) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    dispatch(setGlobalLoading(true));
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) return;

    // Create payment method with stripe to get payment method id
    // Reference: https://docs.stripe.com/js/payment_methods/create_payment_method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: values.cardHolderName,
      },
    });
    if (error) {
      NotifyService.error(error.message);
      return;
    }

    console.log(paymentMethod, 'paymentMethod from stripe');

    const paymentMethodId = paymentMethod.id;
    if (paymentMethodId) {
      dispatch(updatePaymentMethodId(paymentMethodId));
      dispatch(
        attachPaymentMethodAction({
          data: {
            payment_method_id: paymentMethodId,
          },
          onSuccess: () => {
            dispatch(getListCardsAction());
            dispatch(setOpenPaymentModal(false));
          },
          onFinally: () => {
            dispatch(setGlobalLoading(false));
          },
        }),
      );
    }
  };

  return (
    <StyledStripeForm onSubmit={handleSubmit(onSubmit)}>
      <StripeCardNumberField
        // error={Boolean(cardNumberError)}
        // labelErrorMessage={cardNumberError}
        error={Boolean(
          getFieldError(cardNumberComplete, cardNumberError, 'card number'),
        )}
        labelErrorMessage={getFieldError(
          cardNumberComplete,
          cardNumberError,
          'card number',
        )}
        onChange={onElementChange('cardNumberComplete', 'cardNumberError')}
      />
      <InputField
        placeholder="Card holder name"
        control={control}
        name="cardHolderName"
        errorMess={getFieldError(
          cardHolderNameComplete,
          cardHolderNameError,
          'card holder name',
        )}
        rootProps={{
          sx: {
            '& .Mui-error': {
              textAlign: 'left',
            },
          },
        }}
      />
      <Stack flexDirection={'row'} gap={1} justifyContent={'center'}>
        <StripeCardExpiryField
          // error={Boolean(cardExpiredError)}
          // labelErrorMessage={cardExpiredError}
          error={Boolean(
            getFieldError(
              cardExpiredComplete,
              cardExpiredError,
              `card's expiration date`,
            ),
          )}
          labelErrorMessage={getFieldError(
            cardExpiredComplete,
            cardExpiredError,
            `card's expiration date`,
          )}
          onChange={onElementChange('cardExpiredComplete', 'cardExpiredError')}
        />
        <StripeCardCVCField
          isHelpIcon
          tooltipText="three-digit number at the back of your debit card"
          // error={Boolean(cardCVCError)}
          // labelErrorMessage={cardCVCError}
          error={Boolean(
            getFieldError(
              cardCVCComplete,
              cardCVCError,
              `card's security code`,
            ),
          )}
          labelErrorMessage={getFieldError(
            cardCVCComplete,
            cardCVCError,
            `card's security code`,
          )}
          onChange={onElementChange('cardCVCComplete', 'cardCVCError')}
        />
      </Stack>
      <Button type="submit" variant="contained" fullWidth>
        Save Card
      </Button>
    </StyledStripeForm>
  );
};

export default StripeFormProvider;
