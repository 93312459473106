import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { RESPONSE_TYPE } from 'models';
import {
  ADDRESS_LIST_RESPONSE,
  ADDRESS_PARAMS_REQUEST,
  REGION_LIST_RESPONSE,
} from 'models/parent/address';
import {
  ADD_CHILDREN_REQUEST_TYPE,
  CHILDREN_LIST_RESPONSE,
} from 'models/parent/children';

export const parentApi = {
  getChildrenList: async () => {
    return APIInstance.get<RESPONSE_TYPE<CHILDREN_LIST_RESPONSE[]>>(
      `${API.PARENT.GET_CHILDREN}`,
    );
  },
  addChildren: async (data: ADD_CHILDREN_REQUEST_TYPE) => {
    return APIInstance.post(API.PARENT.ADD_CHILDREN, data);
  },
  editChildren: async (data: ADD_CHILDREN_REQUEST_TYPE, id: string) => {
    delete data.id;
    return APIInstance.put(API.PARENT.UPDATE_CHILDREN(id), data);
  },
  deleteChildren: async (id: string) => {
    return APIInstance.delete(API.PARENT.DELETE_CHILDREN(id));
  },
  getAddressList: async () => {
    return APIInstance.get<RESPONSE_TYPE<ADDRESS_LIST_RESPONSE[]>>(
      `${API.PARENT.GET_ADDRESS}`,
    );
  },
  addAddress: async (data: ADDRESS_PARAMS_REQUEST) => {
    return APIInstance.post(API.PARENT.ADD_ADDRESS, data);
  },
  editAddress: async (data: ADDRESS_PARAMS_REQUEST) => {
    return APIInstance.put(API.PARENT.UPDATE_ADDRESS(data.id), data);
  },
  getRegionList: async () => {
    return APIInstance.get<RESPONSE_TYPE<REGION_LIST_RESPONSE[]>>(
      `${API.PARENT.GET_REGION_LIST}`,
    );
  },
  getAddressByPostalCode: async (id: number) => {
    return APIInstance.get(API.PARENT.GET_ADD_BY_CODE(id));
  },
  joinACSubscription: async () => {
    return APIInstance.post(API.PARENT.JOIN_SUBS);
  },
};
