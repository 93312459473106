import styled from '@emotion/styled';
import { Button, Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const LabelTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: ${theme.palette.label.primary};
  text-align: center;
`;

export const LabelSubTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: ${theme.palette.label.primary};
  opacity: 50%;
`;

export const BoxWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  @media (max-width: 414px) {
    flex-direction: column;
  }
  & > div {
    width: 204px;
    height: 284px;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    justify-content: center;
    text-align: center;
    background-color: #ff796e14;
    color: ${theme.palette.primary.main};
  }
`;

export const ButtonGetStart = styled(Button)`
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 50%;
  }
`;
