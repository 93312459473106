import { Box, Button, Stack, Typography } from '@mui/material';
import { APP_IMG } from 'assets';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routers/routes';
import Header from '../Header';

function PageError() {
  const navigate = useNavigate();

  const handleHomePage = () => {
    navigate(APP_ROUTES.JOB.to);
  };

  return (
    <Box>
      <Header />
      <Stack
        width="100%"
        height="calc(100vh - 114px)"
        justifyContent="center"
        alignItems="center"
        pb="141px"
        spacing={3}
      >
        <Box position="relative" width={{ xs: '329px', md: '432px' }}>
          <img width="100%" src={APP_IMG.backgroundEmpty} alt="bg_empty" />
          <Stack position="absolute" bottom={{ xs: 0, md: 10 }} spacing={1}>
            <Typography
              sx={{ opacity: 0.5 }}
              width="100%"
              textAlign="center"
              variant="Web_Title_22"
            >
              Hey, you seem lost
            </Typography>
            <Typography
              sx={{ opacity: 0.5 }}
              width="100%"
              textAlign="center"
              variant="Web_Label_16"
            >
              The page that you were looking for cannot be found
            </Typography>
          </Stack>
        </Box>
        <Button
          onClick={handleHomePage}
          sx={{
            padding: '8.2px 16.4px',
            width: '229px',
          }}
          variant="contained"
        >
          Back to Homepage
        </Button>
      </Stack>
    </Box>
  );
}

export default PageError;
