import { PayloadActionData } from 'models';

export const GET_MY_REQUEST_REQUEST = 'GET_MY_REQUEST_REQUEST';

export const getMyRequestAction = (
  payload: PayloadActionData<{ accessToken?: string }>,
) => ({
  type: GET_MY_REQUEST_REQUEST,
  payload,
});
