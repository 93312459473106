import { DATE_TIME_FORMAT } from 'constants/date';
import { formatDate } from 'helpers';
import moment, { Moment } from 'moment';

export const sleep = (ms: number) => {
  return new Promise((resole) => setTimeout(resole, ms));
};

export const truncateString = (input: string, numberOfCharacters: number) =>
  input.length > numberOfCharacters
    ? `${input.substring(0, numberOfCharacters)}...`
    : input;

export const pluralString = (input: string, count: number) =>
  count > 1
    ? `${count} ${input}s`
    : count === 0
    ? `${input}`
    : `${count} ${input}`;

export const toCapitalize = (str: string) => {
  if (!str) return '';
  const strToLowerCase = str.toLocaleLowerCase();
  return strToLowerCase.charAt(0).toUpperCase() + strToLowerCase.slice(1);
};

export const convertToUtcTime = (date: Date) => {
  if (isNaN(date.getTime())) return null;

  const tzText = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (tzText === 'Asia/Singapore') {
    return date.toISOString();
  } else {
    function getTimeZoneNumber(date: Date, timeZone: string) {
      const options = { timeZone, timeZoneName: 'short' } as any;
      const dateTimeFormat = new Intl.DateTimeFormat([], options);

      const [{ value: tzNumber }] = dateTimeFormat
        .formatToParts(date)
        .filter((part) => part.type === 'timeZoneName');
      return tzNumber.substring(4);
    }

    const tzSGNumber = 8;
    const differenceTimezoneCountInMilSeconds =
      (tzSGNumber - Number(getTimeZoneNumber(date, tzText))) * 60 * 60 * 1000;

    const newDate = new Date(
      date.getTime() - differenceTimezoneCountInMilSeconds,
    );
    return newDate.toISOString();
  }
};

export const convertToLocalTime = (date: Date | string) => {
  let newDate: Date | string;
  if (typeof date === 'string') {
    newDate = new Date(date);
  } else newDate = date;

  if (isNaN(newDate.getTime())) return null;
  const localOffset = newDate.getTimezoneOffset();
  return new Date(
    newDate.getTime() + localOffset * 60 * 1000 + 8 * 60 * 60 * 1000,
  );
};

export const mergeDateTime = (date: moment.Moment, time: Date) => {
  const timeStr = time.toLocaleTimeString('en-GB', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  // const formatDateTimeSGT = `${date?.format(
  //   'YYYY/MM/DD',
  // )} ${timeStr} GMT+0800 (Singapore Standard Time)`;

  const concatDateTime = `${date?.format('YYYY/MM/DD')} ${timeStr} `;
  return new Date(concatDateTime);
};

export enum COMPARE_DATE {
  IS_SAME = 'IS_SAME',
  IS_BEFORE = 'IS_BEFORE',
  IS_AFTER = 'IS_AFTER',
}

export const compareDateTime = (d1: Date, d2: Date) => {
  const time1 = d1.getTime();
  const time2 = d2.getTime();

  switch (true) {
    case time1 < time2:
      return COMPARE_DATE.IS_BEFORE;
    case time1 > time2:
      return COMPARE_DATE.IS_AFTER;
    default:
      return COMPARE_DATE.IS_SAME;
  }
};

export const compareDateOnly = (d1: Moment, d2: Moment) => {
  const date1 = formatDate(d1, {
    format: DATE_TIME_FORMAT.DEFAULT_DATE,
  });
  const date2 = formatDate(d2, {
    format: DATE_TIME_FORMAT.DEFAULT_DATE,
  });

  switch (true) {
    case date1 < date2:
      return COMPARE_DATE.IS_BEFORE;
    case date1 > date2:
      return COMPARE_DATE.IS_AFTER;
    default:
      return COMPARE_DATE.IS_SAME;
  }
};

export const getMilBetweenDate = (d1: Date, d2: Date) => {
  const time1 = d1.getTime();
  const time2 = d2.getTime();

  return time2 - time1;
};

export const isBefore24h = (dateTime: string) => {
  const oneDayMilSecond = 24 * 60 * 60 * 1000;
  const twentyFourHoursBeforeStartTime = new Date(
    new Date(dateTime).getTime() - oneDayMilSecond,
  );

  return new Date() > twentyFourHoursBeforeStartTime;
};

export const checkEqualDate = (inputDate: string) => {
  const todaysDate = new Date();
  return (
    new Date(inputDate).setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
  );
};

// Main function to format the price
export const formatPrice = (price: number | undefined): string => {
  // Handle undefined or invalid input
  if (price === undefined || isNaN(price)) return '$0.00';

  // Round a number to the nearest cent (two decimal places)
  const roundToNearestCent = (price: number): number =>
    Math.round(price * 100) / 100;

  // Format a number with two decimal places and add a dollar sign
  const formatWithTwoDecimals = (price: number): string =>
    `${price.toFixed(2)}`;

  // Round the price to the nearest cent and format
  return `$${formatWithTwoDecimals(roundToNearestCent(price))}`;
};

export const isUndefined = (value: string) => {
  return !value || value === 'undefined';
};
