import styled from '@emotion/styled';
import { Button, Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const FormContainer = styled('form')`
  width: 600px;
  margin: auto;
  margin-top: 60px;
`;

export const LabelCheckbox = styled(Typography)`
  padding-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  & > span {
    color: ${theme.palette.primary.main};
  }
`;

export const LabelWrap = styled(Stack)`
  flex-direction: row;
  padding-left: 34px;
  padding-bottom: 5px;
`;

export const MoreActions = styled(Stack)`
  margin: auto;
  padding-top: 15px;
`;

export const AnChildItem = styled(Stack)`
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const AddChildButton = styled(Stack)`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.25px;
  cursor: pointer;
  color: ${theme.palette.primary.main};
`;

export const NextButton = styled(Button)`
  margin: auto;
  margin-top: 30px;
  width: 100%;
`;
