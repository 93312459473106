import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'config';
import { PayloadActionData, RESPONSE_TYPE } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { paymentApi } from 'services/payment';
import { setGlobalLoading } from 'store/reducers/global';
import {
  updateDataCheckoutPayment,
  updateListCards,
  updatePaymentIntentId,
} from 'store/reducers/payments';
import {
  ATTACH_PAYMENT_METHOD_REQUEST,
  CHECKOUT_SEARCH_FEE_REQUEST,
  GET_LIST_CARD_REQUEST,
  PAY_FOR_SEARCH_FEE_REQUEST,
} from 'store/reducers/payments/actionTypes';
import {
  ATTACH_PAYMENT_METHOD_PAYLOAD,
  CARD_LIST_RESPONSE,
  CHECKOUT_PAYMENT_RESPONSE,
  CHECKOUT_SEARCH_FEE_PAYLOAD,
  PAYMENT_RESPONSE,
  SEARCH_FEE_PAYLOAD,
} from './../../../models/payments';

function* payForSearchFeeRequest(
  action: PayloadAction<PayloadActionData<SEARCH_FEE_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<PAYMENT_RESPONSE>> = yield call(
      paymentApi.searchFee,
      action.payload.data,
    );
    const qrCode =
      response.data.data?.next_action?.paynow_display_qr_code?.image_url_png;
    action.payload.onSuccess?.(qrCode);
    if (response.data.data.id) {
      yield put(updatePaymentIntentId(response.data.data.id));
    }
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* attachPaymentMethodRequest(
  action: PayloadAction<PayloadActionData<ATTACH_PAYMENT_METHOD_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(paymentApi.attachPaymentMethod, action.payload.data);
    action.payload.onSuccess?.();
    NotifyService.success('Add card successfully');
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* getListCardRequest() {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<CARD_LIST_RESPONSE[]>> =
      yield call(paymentApi.getAllCards);
    yield put(updateListCards(response.data.data));
  } catch (error) {
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
  }
}

function* checkoutForSearchFeeRequest(
  action: PayloadAction<PayloadActionData<CHECKOUT_SEARCH_FEE_PAYLOAD>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>> =
      yield call(paymentApi.checkoutSearchFee, action.payload.data);
    action.payload.onSuccess?.(response.data.data);
    yield put(updateDataCheckoutPayment(response.data.data));
  } catch (error) {
    action.payload.onFailed?.();
    NotifyService.error(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* searchFeeSaga() {
  yield takeLatest(PAY_FOR_SEARCH_FEE_REQUEST, payForSearchFeeRequest);
  yield takeLatest(ATTACH_PAYMENT_METHOD_REQUEST, attachPaymentMethodRequest);
  yield takeLatest(GET_LIST_CARD_REQUEST, getListCardRequest);
  yield takeLatest(CHECKOUT_SEARCH_FEE_REQUEST, checkoutForSearchFeeRequest);
}
export default searchFeeSaga;
