import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { DeleteIcon, EditIcon } from 'assets';
import { InputField, UploadAvatar } from 'components';
import PopupConfirm, { TYPE_ICON } from 'components/popup/PopupConfirm';
import { NotifyService } from 'config/notify';
import yup from 'config/yup.custom';
import { ageRangeOptions } from 'features/create-request/helpers';
import { useDebounce } from 'hooks';
import { USER_TYPE } from 'models';
import { CHILDREN_LIST_RESPONSE } from 'models/parent/children';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { profileApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getUserProfileAction } from 'store/reducers/auth/actionTypes';
import { setGlobalLoading } from 'store/reducers/global';
import {
  deleteChildrenAction,
  getChildrenListAction,
} from 'store/reducers/parent/actionTypes';
import PopupAddEditChildren from '../../components/PopupAddEditChildren';
import PopupChangePassword from '../../components/PopupChangePassword';
import { ProfileWrapper } from './styles';

interface IEditProfileForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profilePicture: string;
}

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    phoneNumber: yup.mixed().required('Phone number is required'),
  })
  .required();

export const EditProfile = () => {
  const dispatch = useAppDispatch();
  const { globalLoading } = useAppSelector((state) => state.global);
  const { childrenList } = useAppSelector((state) => state.parent);
  const { parent } = useAppSelector((state) => state.auth);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [openAddEditChildren, setOpenAddEditChildren] =
    useState<boolean>(false);
  const [openDeleteChildren, setOpenDeleteChildren] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedChildren, setSelectedChildren] =
    useState<CHILDREN_LIST_RESPONSE>();

  const { handleSubmit, control, reset, setValue, watch } =
    useForm<IEditProfileForm>({
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset({
      firstName: parent.first_name || '',
      lastName: parent.last_name || '',
      email: parent.email || '',
      phoneNumber: `${parent.country_code}${parent.phone_number}` || '',
      profilePicture: parent.profile_picture,
    });
  }, [parent, reset]);

  const onRefreshData = () => {
    dispatch(
      getUserProfileAction({
        data: {
          userType: USER_TYPE.PARENT,
        },
      }),
    );
  };

  const onSubmit = useDebounce(async (data: IEditProfileForm) => {
    if (globalLoading || !parent) return;
    dispatch(setGlobalLoading(true));
    await profileApi
      .updateProfile({
        first_name: data.firstName,
        last_name: data.lastName,
        profile_picture: data.profilePicture,
      })
      .then(() => {
        onRefreshData();
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
  }, 500);

  const onChangeProfilePicture = async (file: File) => {
    if (!parent) return;
    dispatch(setGlobalLoading(true));
    const formData = new FormData();
    formData.append('file', file);
    await profileApi
      .uploadPicture(formData)
      .then(({ data }) => {
        setValue('profilePicture', data?.data.url);
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        dispatch(setGlobalLoading(false));
      });
  };

  const handleAddChild = () => {
    setIsEdit(false);
    setOpenAddEditChildren(true);
  };

  const handleDeleteChild = () => {
    dispatch(
      deleteChildrenAction({
        data: {
          id: selectedChildren.id,
        },
        onSuccess: () => {
          dispatch(getChildrenListAction());
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getChildrenListAction());
  }, [dispatch]);

  return (
    <ProfileWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack mb={4.5}>
        <Typography variant="Web_Title_22">Edit Profile</Typography>
        <Typography variant="Web_Label_18">Edit your profile</Typography>
      </Stack>
      <Stack spacing={4}>
        <UploadAvatar
          role={USER_TYPE.PARENT}
          onChange={(val) => onChangeProfilePicture(val)}
          value={watch('profilePicture')}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputField
                control={control}
                name="firstName"
                label="First Name*"
                placeholder="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                control={control}
                name="lastName"
                label="Last Name*"
                placeholder="Last Name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                disabled
                control={control}
                name="email"
                label="Email"
                placeholder="Email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                disabled
                control={control}
                name="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
              />
            </Grid>
          </Grid>
          <Stack spacing={3} mt={3}>
            <Button
              variant="contained"
              type="submit"
              sx={{ padding: '8px 16px' }}
            >
              Save Profile
            </Button>
          </Stack>
        </Box>
        <Divider sx={{ borderColor: '#D9D9D9' }} />
        <Stack spacing={1}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography fontSize={15} fontWeight={400}>
              My Children
            </Typography>
            <Button onClick={handleAddChild} sx={{ padding: 0, height: 30 }}>
              Add Child
            </Button>
          </Stack>
          <Stack spacing={2}>
            {childrenList.map((child) => (
              <Stack
                direction="row"
                justifyContent={'space-between'}
                alignItems="center"
                key={child.id}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Avatar
                    sx={{ width: 44, height: 44, bgcolor: 'primary.main' }}
                  >
                    {child.picture ?? child.full_name.charAt(0)}
                  </Avatar>
                  <Stack spacing={0.25}>
                    <Typography variant="Title_Menu_14">
                      {child.full_name}
                    </Typography>
                    <Typography variant="Small_Body_12">
                      {
                        ageRangeOptions.find(
                          (range) => range.id === child.age_range,
                        ).name
                      }
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <DeleteIcon
                    sx={{ color: 'light.secondary', cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedChildren(child);
                      setOpenDeleteChildren(true);
                    }}
                  />
                  <EditIcon
                    sx={{ color: 'primary.main', cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedChildren(child);
                      setIsEdit(true);
                      setOpenAddEditChildren(true);
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>

      <PopupChangePassword
        openPop={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
      <PopupAddEditChildren
        isEdit={isEdit}
        selectedChildren={selectedChildren}
        openPop={openAddEditChildren}
        onClose={() => setOpenAddEditChildren(false)}
      />
      {openDeleteChildren && (
        <PopupConfirm
          typeIcon={TYPE_ICON.HELP}
          title="Delete a Child"
          content={
            <Typography>Are you sure you want to delete this entry?</Typography>
          }
          textButton2="Back"
          onSubmit={handleDeleteChild}
          handleClose={() => setOpenDeleteChildren(false)}
        />
      )}
    </ProfileWrapper>
  );
};

export default EditProfile;
