import Cookies from 'js-cookie';

export const getLocalStorage = (key: string): string => {
  return localStorage.getItem(key);
};

export const setLocalStorage = (key: string, data: string) => {
  localStorage.setItem(key, data);
};

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const getCookies = (name: string) => {
  Cookies.get(name);
};
export const setCookies = (name: string, value: string, expires?: number) => {
  Cookies.set(name, value, {
    httpOnly: true,
    expires: expires ?? 1,
  });
};

export const removeCookies = (name: string) => {
  Cookies.remove(name);
};
