export const SYS_MESS = {
  ERROR: {
    COMMON: {
      FILL_DATA: 'Please fill in this field',
    },
    PASSWORD_REQUIRED: 'Password is required',
    NAME_OF_CHILD_REQUIRED: 'Name of Child is required',
    DOB_REQUIRED: 'Date of birth is required',
    AGE_REQUIRED: 'Age is required',
    CHECKBOX_REQUIRED: 'Please tick the checkbox',
    ADDRESS_REQUIRED: 'Address is required',
    SERVICE_PERIOD_REQUIRED: 'Service period is required',
    ESTIMATED_HOURS_REQUIRED: 'Estimated hours per week is required',
    START_DATE_REQUIRED: 'Start date is required',
    END_DATE_REQUIRED: 'End date is required',
    START_TIME_REQUIRED: 'Start time is required',
    END_TIME_REQUIRED: 'End time is required',
    DAY_OF_WEEK_REQUIRED: 'Please choose this field',
    SPECIAL_REQUIREMENTS_REQUIRED: 'Special requirements are required',
    CHILDREN_REQUIRED: 'At least one child is required',
    PETS_REQUIRED: 'At least one pet is required',
    POSTAL_CODE_REQUIRED: 'Postal code is required',
    BLOCK_NO_REQUIRED: 'Block no is required',
    STREET_ADDRESS_REQUIRED: 'Street address is required',
    UNIT_NO_REQUIRED: 'Unit no is required',
    BUILDING_NAME_REQUIRED: 'Building name is required',
    REGION_REQUIRED: 'Region is required',
    ADDRESS_NAME_REQUIRED: 'Name this address is required',
    END_TIME_LARGE:
      'The end time must be at least 2 hours greater than the start time',

    PAYMENTS: {
      STRIPE_NOT_FOUND: "Stripe.js hasn't loaded yet",
      CARD_NOT_FOUND: 'Card element not found',
      NUMBER_CARD: 'Your card number is incomplete.',
      EXPIRED_DATE_CARD: `Your card's expiration date is incomplete.`,
      CVC_CARD: `Your card's security code is incomplete.`,
      HOLDER_NAME_CARD: 'Your card holder name is incomplete.',
    },
  },
  SUCCESS: {
    DELETE_POST: 'Success',
    SUBMISSION_SUCCESS: 'Form submitted successfully',
    PAYMENT: 'Successful Payment',
    UPDATE_JOB: 'Update job successfully',
    BOOKING_UPDATED: 'Booking Updated',
    BOOKING_CANCELED: 'Booking Cancelled',
    SITTER_REJECTED: 'Sitter Rejected',
    SITTER_ACCEPTED: 'Request to Arrange Trial Accepted.',
  },
};
