import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { STORAGE_KEY } from 'constants/global';
import { ACTION_TYPE, PayloadActionData, RESPONSE_TYPE } from 'models';
import { AC_BASE_REQUEST_SITTER, OVERVIEW_RESPONSE } from 'models/overview';
import { call, put, takeLatest } from 'redux-saga/effects';
import { overviewApi } from 'services/overview';
import { setGlobalLoading } from 'store/reducers/global';
import {
  updateACRequestSitterStatus,
  updateMyRequest,
} from 'store/reducers/overview';
import { GET_MY_REQUEST_REQUEST } from 'store/reducers/overview/actionTypes';
import { getLocalStorage } from 'utils';

function* getMyRequestRequest(
  action: PayloadAction<PayloadActionData<{ accessToken?: string }>>,
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<RESPONSE_TYPE<OVERVIEW_RESPONSE>> =
      yield call(
        overviewApi.getDataOverview,
        getLocalStorage(STORAGE_KEY.ACCESS_TOKEN) ||
          action.payload.data?.accessToken,
      );
    yield put(updateMyRequest(response.data.data));
    const isAcceptedAnySitter = response.data.data.ac_base_request_sitters.some(
      (sitter: AC_BASE_REQUEST_SITTER) =>
        sitter.status === ACTION_TYPE.ACCEPTED,
    );
    yield put(
      updateACRequestSitterStatus(
        isAcceptedAnySitter ? ACTION_TYPE.ACCEPTED : ACTION_TYPE.PENDING,
      ),
    );
    action.payload.onSuccess?.(response.data.data);
  } catch (error) {
    yield put(setGlobalLoading(false));
    action.payload.onFailed?.(error);
  } finally {
    yield put(setGlobalLoading(false));
    action.payload.onFinally?.();
  }
}

function* getMyRequestSaga() {
  yield takeLatest(GET_MY_REQUEST_REQUEST, getMyRequestRequest);
}

export default getMyRequestSaga;
