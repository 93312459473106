import { APIInstance } from 'config';
import { API } from 'constants/apiEndpoints';
import { STORAGE_KEY } from 'constants/global';
import { RESPONSE_TYPE } from 'models';
import {
  ATTACH_PAYMENT_METHOD_PAYLOAD,
  CARD_LIST_RESPONSE,
  CHECKOUT_PAYMENT_RESPONSE,
  CHECKOUT_SEARCH_FEE_PAYLOAD,
  CREDIT_BALANCE_RESPONSE,
  PAY_JOB_PAYLOAD,
  PAYMENT_RESPONSE,
  PURCHASE_PACKAGE_PAYLOAD,
  SEARCH_FEE_PAYLOAD,
} from 'models/payments';
import { getLocalStorage } from 'utils';

export const paymentApi = {
  getAllCards: async () => {
    return APIInstance.get<RESPONSE_TYPE<CARD_LIST_RESPONSE[]>>(
      `${API.PAYMENT.GET_ALL_CARDS}`,
    );
  },

  // SEARCH FEE
  searchFee: async (data: SEARCH_FEE_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.PAY_SEARCH_FEE,
      data,
    );
  },
  checkoutSearchFee: async (data: CHECKOUT_SEARCH_FEE_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>>(
      API.PAYMENT.CHECKOUT.CHECKOUT_SEARCH_FEE,
      data,
    );
  },

  // JOB
  trialJob: async (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.TRIAL_JOB,
      data,
    );
  },
  extendTrialJob: async (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.PAY_EXTEND_TRIAL_JOB,
      data,
    );
  },
  normalJob: async (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.NORMAL_JOB,
      data,
    );
  },
  extendJob: async (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.EXTEND_JOB,
      data,
    );
  },

  payJobByTopUp: (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.PAY_JOB_BY_TOP_UP,
      data,
    );
  },

  checkoutTrialJob: async (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>>(
      API.PAYMENT.CHECKOUT.CHECKOUT_TRIAL_JOB,
      data,
    );
  },

  checkoutACJob: async (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>>(
      API.PAYMENT.CHECKOUT.CHECKOUT_AC_JOB,
      data,
    );
  },

  checkoutExtendACJob: async (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>>(
      API.PAYMENT.CHECKOUT.CHECKOUT_EXTEND,
      data,
    );
  },

  payExtendJobByTopUp: (data: PAY_JOB_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.PAY_EXTEND_JOB_TOP_UP,
      data,
    );
  },

  // PACKAGE/CREDITS
  getCreditBalance: async (accessToken?: string) => {
    return APIInstance.get<RESPONSE_TYPE<CREDIT_BALANCE_RESPONSE>>(
      `${API.PAYMENT.GET_CREDIT_BALANCE}`,
      {
        headers: {
          Authorization:
            'Bearer ' +
            (getLocalStorage(STORAGE_KEY.ACCESS_TOKEN) ?? accessToken),
        },
      },
    );
  },
  attachPaymentMethod: async (data: ATTACH_PAYMENT_METHOD_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE>(
      API.PAYMENT.ATTACH_PAYMENT_METHOD,
      data,
    );
  },
  createPaymentMethod: async (data: any): Promise<any> => {
    return APIInstance.post(API.PAYMENT.CREATE_PAYMENT_METHOD, data);
  },
  purchasePackage: async (data: PURCHASE_PACKAGE_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<PAYMENT_RESPONSE>>(
      API.PAYMENT.BUY_PACKAGE,
      data,
    );
  },
  checkoutPurchasePackage: async (data: PURCHASE_PACKAGE_PAYLOAD) => {
    return APIInstance.post<RESPONSE_TYPE<CHECKOUT_PAYMENT_RESPONSE>>(
      API.PAYMENT.CHECKOUT.CHECKOUT_PAY_PACKAGE,
      data,
    );
  },
};
