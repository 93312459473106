import { Stack, Typography } from '@mui/material';
import SitterPanel from 'components/common/SitterPanel';
import { SITTER_TYPE } from 'models/sitter';

interface Props {
  sitter: SITTER_TYPE;
}

export const SectionCertifications = ({ sitter }: Props) => {
  const items = sitter?.checkLists.find(
    (item) => item.section_name === 'Certifications',
  );

  return (
    <Stack spacing={1}>
      <Typography variant="Web_Title_22">Certifications</Typography>
      <Stack spacing={{ xs: 1, md: 3 }} direction={{ xs: 'column', md: 'row' }}>
        <SitterPanel
          item={{ section_title: 'Certifications', items: items?.items ?? [] }}
        />
      </Stack>
    </Stack>
  );
};
