import { Stack, Typography } from '@mui/material';
import { theme } from 'theme';
import { menuRouteParent } from '../constants';
import { StyledNavLink } from './styles';

const MenuLeft = () => {
  return (
    <Stack
      width={{ xs: '100%', md: '200px' }}
      spacing={3}
      position="fixed"
      top={125}
      marginTop={1.5}
    >
      {menuRouteParent.map((items, index) => {
        return (
          <Stack spacing={1} key={index}>
            <Typography
              color={theme.palette.grey1.dark}
              textTransform="uppercase"
              variant="Title_Menu_14"
            >
              {items.title}
            </Typography>
            <Stack spacing={1}>
              {items.items.map((router, index) => {
                return (
                  <StyledNavLink
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    key={index}
                    to={router.path}
                  >
                    <Typography variant="Web_Label_16">
                      {router.label}
                    </Typography>
                  </StyledNavLink>
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MenuLeft;
