import styled from '@emotion/styled';
import { FormControl, Stack, Typography } from '@mui/material';
import { theme } from 'theme';

export const PaymentContainer = styled(Stack)`
  width: 580px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 60px;
  gap: 24px;
`;

export const FeeSummaryContainer = styled(Stack)`
  gap: 18px;
  & > div {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const PaymentMethodContainer = styled(Stack)``;

export const PaymentTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
`;

export const TotalAmount = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #231f20;
`;

export const StyledFormControl = styled(FormControl)`
  & .MuiFormControlLabel-root {
    background: ${theme.palette.light.shades4};
    border-radius: 7px;
    align-items: center;
    flex-direction: row-reverse;
    padding: 4px 20px;
    margin-bottom: 5px;
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;
    & .payment-method-label {
      align-items: center;
      flex-direction: row;
      gap: 16px;
      & > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
`;

export const StyledAddCard = styled(Stack)`
  background: ${theme.palette.light.shades4};
  border-radius: 7px;
  align-items: center;
  flex-direction: row-reverse;
  padding: 12px 20px;
  margin-bottom: 5px;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const StyledCreditBalance = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  height: 45px;
  padding: 15px;
  margin-bottom: 15px !important;
  & button {
    min-width: 50px;
    height: 30px;
    padding: 13px 10px;
    font-size: 14px;
    font-weight: 400;
  }
`;
