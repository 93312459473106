import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ACTION_TYPE } from 'models';
import { OVERVIEW_RESPONSE } from 'models/overview';

export interface OverviewType {
  sitterId: string;
  acRequestStatus: ACTION_TYPE;
  myRequest: OVERVIEW_RESPONSE;
}

const initialState: OverviewType = {
  sitterId: '',
  acRequestStatus: ACTION_TYPE.PENDING,
  myRequest: null,
};

const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    updateSitterId(state: OverviewType, action: PayloadAction<string>) {
      state.sitterId = action.payload;
    },
    updateACRequestSitterStatus(
      state: OverviewType,
      action: PayloadAction<ACTION_TYPE>,
    ) {
      state.acRequestStatus = action.payload;
    },
    updateMyRequest(
      state: OverviewType,
      action: PayloadAction<OVERVIEW_RESPONSE>,
    ) {
      state.myRequest = action.payload;
    },
  },
});

// Actions
export const { updateSitterId, updateACRequestSitterStatus, updateMyRequest } =
  overviewSlice.actions;

export default overviewSlice.reducer;
