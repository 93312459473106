import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { theme } from 'theme';
import { transientOptions } from 'utils/transientOptions';

export const StyledButtonWrapper = styled(Stack, transientOptions)<{
  $twoButton: boolean;
}>`
  flex-direction: row;
  width: 100%;
  gap: 8px;
  justify-content: center;
  & button {
    width: ${({ $twoButton }) => (!$twoButton ? 'auto' : '50%')};
  }
  & .btn-2 {
    background-color: ${theme.palette.secondary.main};
  }
`;
