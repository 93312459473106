import { APP_ROUTES } from 'routers/routes';

export const menuRouteParent = [
  {
    title: 'Main Menu',
    items: [
      {
        label: 'Overview',
        path: APP_ROUTES.OVERVIEW.to,
        name: 'overview',
      },
      {
        label: 'Bookings',
        path: APP_ROUTES.JOB.to,
        name: 'bookings',
      },
      {
        label: 'Credits',
        path: APP_ROUTES.CREDITS.to,
        name: 'credits',
      },
      {
        label: 'Edit Profile',
        path: APP_ROUTES.PROFILE.to,
        name: 'profile',
      },
    ],
  },
];

export const NOTIS = 2;
