import { Divider, Stack } from '@mui/material';
import { formatDate } from 'helpers';
import { SUBSCRIPTION_PURCHASED_ITEM } from 'models/credits';
import { theme } from 'theme';
import {
  BookingCreditContainer,
  StyledDetailText,
  StyledDetailWrap,
  StyledTitleWrap,
} from './styles';

const TEXT_COLORS = {
  secondary: '#00000099',
};

interface TransactionItemProps {
  data: SUBSCRIPTION_PURCHASED_ITEM;
}

interface DetailContentProps {
  label?: string;
  value?: string;
  amount?: number;
}

function TransactionItem({ data }: TransactionItemProps) {
  const DetailContent = ({ label, value, amount }: DetailContentProps) => (
    <Stack
      spacing={0.25}
      textAlign={label === 'Total Amount' ? 'right' : 'left'}
    >
      <StyledDetailText color={TEXT_COLORS.secondary}>{label}</StyledDetailText>
      <StyledDetailText>
        {!(label === 'Total Amount') ? value : <span>${amount} </span>}
      </StyledDetailText>
    </Stack>
  );

  return (
    <BookingCreditContainer>
      <StyledTitleWrap>
        {data.number} - {data.package.name} Plan
      </StyledTitleWrap>
      <Divider sx={{ borderColor: theme.palette.light.quaternary }} />
      <StyledDetailWrap>
        <Stack flexDirection="row" gap={3.5}>
          <DetailContent
            label="Purchase Date"
            value={formatDate(data.createdAt, {
              format: 'DD MMM YYYY HH:MM',
            })}
          />
          <DetailContent
            label="Expiring in"
            value={formatDate(data.updatedAt, {
              format: 'DD MMM YYYY',
            })}
          />
        </Stack>
        <DetailContent label="Total Amount" amount={data.balance} />
      </StyledDetailWrap>
    </BookingCreditContainer>
  );
}

export default TransactionItem;
