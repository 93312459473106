import styled from '@emotion/styled';
import { CircularProgress, keyframes, Stack, Typography } from '@mui/material';
import { TOTAL_TIME_EXPIRED_QRCODE } from 'constants/global';
import React, { useEffect, useState } from 'react';

interface PaymentPayNowProps {
  valueQrCode: string;
}

type TimeUnit = {
  minutes: number;
  remainingSeconds: number;
};

const FaceInAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(0.70);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const StyledImage = styled('img')`
  width: 254px;
  height: 254px;
  animation: ${FaceInAnimation} 0.7s ease-in-out;
`;

const LoadingOverlay = styled(Stack)({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PaymentPayNow = ({ valueQrCode }: PaymentPayNowProps) => {
  const [countDown, setCountDown] = useState<number>(TOTAL_TIME_EXPIRED_QRCODE);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  // Preload image
  useEffect(() => {
    if (!valueQrCode) return;

    const img = new Image();
    img.src = valueQrCode;
    img.onload = () => {
      setIsImageLoaded(true);
    };
  }, [valueQrCode]);

  useEffect(() => {
    setCountDown(TOTAL_TIME_EXPIRED_QRCODE);
    const interval = setInterval(() => {
      setCountDown((pre) => {
        if (pre <= 1) {
          clearInterval(interval);
          return 0;
        }
        return pre - 1;
      });
    }, 1_000);
    return () => clearInterval(interval);
  }, []);

  const formatCountdown = (seconds: number): string => {
    const EXPIRED_MESSAGE = 'QR Code is expired';
    if (seconds <= 0) {
      return EXPIRED_MESSAGE;
    }

    // Handles the time unit calculations
    const getTimeUnits = (seconds: number): TimeUnit => ({
      minutes: Math.floor(seconds / 60),
      remainingSeconds: seconds % 60,
    });

    // Handles the formatting of numbers
    const padWithZero = (num: number): string =>
      num.toString().padStart(2, '0');

    return `${padWithZero(getTimeUnits(seconds).minutes)}:${padWithZero(
      getTimeUnits(seconds).remainingSeconds,
    )}`;
  };

  if (!isImageLoaded) {
    return (
      <LoadingOverlay>
        <CircularProgress />
      </LoadingOverlay>
    );
  }

  return (
    <React.Fragment>
      <StyledImage src={valueQrCode} alt="qr_code" />
      <Typography className="countdown">
        {formatCountdown(countDown)}
      </Typography>
    </React.Fragment>
  );
};

export default PaymentPayNow;
